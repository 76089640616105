const mock = require('mockjs2')
const { builder } = require('../utils')

const setting = {
  ok: builder(),
  error: builder(null, 500),
  info: builder(mock.mock({
    id: 1000,
    nickname: '@cname',
    permissions: ['add', 'edit']
  })),
  list: builder(
    {
      current_page: 1,
      total: 2,
      data: [
        {
          id: 1,
          buttom: '运营',
          path: '[GET] home/header',
          permissions: 'admin-index-index',
          router: '/page/list',
          rulestatus: 0,//0隐藏 1显示
          children: [
            {
              id: 3,
              buttom: '运营',
              path: '[GET] home/header',
              permissions: 'admin-index-index',
              router: '/page/list',
              rulestatus: 0,//0隐藏 1显示
            }
          ]
        },
        {
          id: 2,
          buttom: '首页',
          path: '[GET] home/header',
          permissions: 'admin-index-index',
          router: '/page/list',
          rulestatus: 1,//0隐藏 1显示
        }
      ]
    }

  )
}

module.exports = {
  'POST /setting/list': setting.list
}
