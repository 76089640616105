export default {
  bonus: {
    detail_columns_pay: [
      {
        title: "支付方式",
        dataIndex: "pay_way_txt",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
    ],
    detail_columns_money: [
      {
        title: "订单号",
        dataIndex: "order_no",
        key: "_0",
        ellipsis: true,
        width: 250,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "商品ID",
        dataIndex: "product_id",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "订单商品",
        dataIndex: "product_name",
        key: "_2",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "支付明细",
        dataIndex: "payDetail",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "佣金",
        dataIndex: "amount",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
    ],
    detail_columns_money_two:[
      {
        title: "订单号",
        dataIndex: "order_no",
        key: "_0",
        ellipsis: true,
        width: 250,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "商品ID",
        dataIndex: "product_id",
        key: "_1",
        ellipsis: true,
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "订单商品",
        dataIndex: "product_name",
        key: "_2",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "支付明细",
        dataIndex: "payDetail",
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "佣金",
        dataIndex: "amount",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "修改原因",
        dataIndex: "content",
        key: "_5", customRender: (str) => str || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
    ],
    columns_b: [
      {
        title: "佣金订单编号",
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        width: 220,
        scopedSlots: { customRender: "_0" },
      },
      {
        // title: "门店",
        slots: { title: '_shop' },
        dataIndex: "shop",
        key: "_1",
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: "_1" },
      },
      {
        slots: { title: '_plan' },
        // title: "合伙人方案",
        dataIndex: "partnerPlan",
        key: "_10",
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: "_10" },
      },
      {
        title: "订单收款金额",
        dataIndex: "total_amount",
        key: "_2",
        width: 130,
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "佣金",
        dataIndex: "commission_amount",
        width: 100,
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "合伙人",
        dataIndex: "scheme_name",
        width: 180,
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "收款客户",
        dataIndex: "scheme_name",
        width: 180,
        key: "_5",
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "类型",
        dataIndex: "type",
        key: "_6",
        width: 100,
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "状态",
        // slots: { title: '_status' },
        dataIndex: "statusText",
        width: 100,
        key: "_7",
        ellipsis: true,
        scopedSlots: { customRender: "_7" },
      },
      {
        slots: { title: '_time' },
        // title: "收款时间",
        dataIndex: "pay_time",
        width: 180,
        key: "_9",
        ellipsis: true,
        customRender: (str) => str || "--",
        scopedSlots: { customRender: "_9" },
      },
      {
        title: "结算时间",
        dataIndex: "settlement_time",
        key: "_11",
        width: 170,
        customRender: (str) => str || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_11" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 90,
        fixed: 'right',
        scopedSlots: { customRender: "_8" },
      },
    ],
    columns_a: [
      {
        title: "佣金订单编号",
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        width: 220,
        scopedSlots: { customRender: "_0" },
      },
      {
        // title: "门店",
        slots: { title: '_shop' },
        dataIndex: "shop",
        key: "_1",
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: "_1" },
      },
      {
        slots: { title: '_plan' },
        // title: "合伙人方案",
        dataIndex: "partnerPlan",
        key: "_10",
        width: 200,
        ellipsis: true,
        scopedSlots: { customRender: "_10" },
      },
      {
        title: "订单收款金额",
        dataIndex: "total_amount",
        key: "_2",
        width: 130,
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "佣金",
        dataIndex: "commission_amount",
        width: 100,
        key: "_3",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "合伙人",
        dataIndex: "scheme_name",
        width: 220,
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "收款客户",
        dataIndex: "scheme_name",
        width: 210,
        key: "_5",
        ellipsis: true,
        scopedSlots: { customRender: "_5" },
      },
      {
        title: "类型",
        dataIndex: "type",
        key: "_6",
        width: 100,
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "状态",
        // slots: { title: '_status' },
        dataIndex: "statusText",
        width: 100,
        key: "_7",
        ellipsis: true,
        scopedSlots: { customRender: "_7" },
      },
      {
        slots: { title: '_time' },
        // title: "收款时间",
        dataIndex: "pay_time",
        width: 180,
        key: "_9",
        ellipsis: true,
        customRender: (str) => str || "--",
        scopedSlots: { customRender: "_9" },
      },
      {
        title: "结算时间",
        dataIndex: "settlement_time",
        key: "_11",
        width: 170,
        customRender: (str) => str || "--",
        ellipsis: true,
        scopedSlots: { customRender: "_11" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 186,
        fixed: 'right',
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
  brokerage: {
    detail_columns: [
      {
        title: "合伙人",
        dataIndex: "name",
        key: "_0",
        ellipsis: true,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "消费股数量",
        dataIndex: "subscribed_shares",
        key: "_1",
        ellipsis: true,
        customRender: (str) => str + "股" || "--",
        scopedSlots: { customRender: "_1" },
      },
      {
        title: "预计分红",
        dataIndex: "predict_dividend_amount",
        key: "_2",
        ellipsis: true,
        customRender: (str) => "¥" + str || "¥ 0.00",
        scopedSlots: { customRender: "_2" },
      },
    ],
    columns_a: [
      {
        title: "门店分红订单编号",
        dataIndex: "sn",
        key: "_0",
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: "_0" },
      },
      {
        title: "门店",
        dataIndex: "shop_name",
        key: "_1",
        ellipsis: true,
        customRender: (str) => str || "--",
        scopedSlots: { customRender: "_1" },
      },
      {
        // title: "合伙人方案",
        slots: { title: '_plan' },
        dataIndex: "scheme_name",
        key: "_10",
        ellipsis: true,
        scopedSlots: { customRender: "_10" },
      },
      {
        title: "预计分红",
        dataIndex: "predict_dividend_amount",
        key: "_2",
        customRender: (str) => "¥" + str || "¥ 0.00",
        ellipsis: true,
        scopedSlots: { customRender: "_2" },
      },
      {
        title: "实际分红",
        dataIndex: "actual_dividend_amount",
        key: "_3",
        customRender: (str) => "¥" + str || "¥ 0.00",
        ellipsis: true,
        scopedSlots: { customRender: "_3" },
      },
      {
        title: "统计月份",
        dataIndex: "dividend_month",
        key: "_4",
        ellipsis: true,
        scopedSlots: { customRender: "_4" },
      },
      {
        title: "状态",
        dataIndex: "status_text",
        key: "_6",
        ellipsis: true,
        scopedSlots: { customRender: "_6" },
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "_8",
        width: 200,
        scopedSlots: { customRender: "_8" },
      },
    ],
  },
};
