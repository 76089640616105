<template>
  <div :class="['layout-base', layoutClassName]">
    <nav-header />
    <!-- 这里有抽屉  包括一级菜单 -->
    <div class="contentBox">
      <!-- 左侧二级菜单 -->
      <mrj-menu> </mrj-menu>
      <div
        key="tab"
        class="layout-base__body mey"
      >
        <mrj-tab class="db" />
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { NavHeader, NavMenu, NavBreadcrumb, NavTab, DevDrawer, MrjMenu, MrjTab } from '@/components'
// import Msg from '@/components/mrj-menu/msg'
export default {
  name: 'layout-base',
  components: {
    NavHeader,
    NavMenu,
    NavBreadcrumb,
    NavTab,
    DevDrawer,
    MrjMenu,
    MrjTab
  },
  data() {
    return {
      isRouterAlive: true,
      // 如果是开发环境或者是预览环境的话显示项目配置入口
      showDevDrawer: process.env.NODE_ENV === 'development' || process.env.VUE_APP_BUILD_ENV === 'preview'
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },

  },
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapGetters(['showBreadcrumb']),
    ...mapState({
      layout: state => state.app.layout,
      navMode: state => state.app.navMode,
      isSideNav: state => state.app.layout === 'side',
      menuCollapsed: state => state.app.menuCollapsed,
      logoFollowMenu: state => state.app.logoMode === 'followMenu'
    }),
    layoutClassName() {
      const foldName = this.menuCollapsed ? 'fold' : 'unfold'
      const logoName = this.logoFollowMenu ? 'menu' : 'header'
      return `layout-base--${this.layout} layout-base--${this.navMode} layout-base--${foldName} layout-base--${logoName}`
    }
  },
  mounted() {

  },
}
</script>

<style lang="less" scoped>
.layout-base {
  width: 100%;
  min-width: @container-min-width;
  // padding-top: @top-header-height;
  // box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.1);
  background: #f0f2f5;
  &--fold {
    padding-left: @side-menu-fold-width;
  }
  &--unfold {
    // padding-left: @side-menu-unfold-width;
  }
  &--menu {
    &.layout-base--fold.layout-base--side :deep(.nav-header) {
      // padding-left: @side-menu-fold-width;
    }
    &.layout-base--unfold.layout-base--side :deep(.nav-header) {
      // padding-left: @side-menu-unfold-width;
    }
    :deep(.nav-header) {
      z-index: 500;
    }
    :deep(.nav-menu) {
      z-index: 600;
      top: 0;
      height: 100vh;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - @top-header-height);
    background-color: @gray-border-background;
    flex: 1;
    min-width: calc(100% - 181px);
    // max-width: calc( 100% - 69px );
  }
  &__content {
    // 兼容 IE11
    min-height: 1px;
  }
  &__breadcrumb-wrapper {
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
  &--top {
    padding-left: 0;
    :deep(.nav-breadcrumb) {
      padding-left: 0;
      padding-right: 0;
    }
    :deep(.nav-header) {
      padding-left: 0;
    }
    :deep(.nav-menu) {
      height: auto;
    }
    :deep(.layout-wrapper > .container) {
      max-width: @container-max-width;
      min-width: @container-min-width;
    }
    .layout-base__breadcrumb-wrapper .container {
      max-width: @container-max-width;
      min-width: @container-min-width;
      width: 100%;
      padding: 0 @common-spacing;
      margin: 0 auto;
    }
    :deep(.ak-tabs-nav) {
      margin: 0 0 16px;
    }
  }
  &--tab {
    :deep(.page-detail .layout-wrapper:first-child),
    :deep(.page-form .layout-wrapper:first-child) {
      padding-top: 16px;
    }
  }
}
.contentBox {
  height: calc(100vh - 52px);
  width: 100%;
  display: flex;
}
</style>
