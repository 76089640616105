<template>
  <div class="personnelfiles flex-1">
    <h2 class="twoh3 mgl-20">
      <span class="tpointer"></span>
      <span class="ttxt">分红项目收益总额</span>
      <a-month-picker class="mgl-12" format="YYYY-MM" v-model="query.dividend_month" @change="setMonth" @openChange="isactive = !isactive">
        <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
      </a-month-picker>
      <a-button type="primary" class="mgl-20" @click="setCreate">生成{{dividend_month}}分红订单</a-button>
    </h2>
    <div class="item_cards mgb-20 mgr-20 mgl-20" style="justify-content:space-around">
      <div class="text-center">
        <div class="toptext2">产品业绩(收现)
          <a-tooltip placement="top">
            <template slot="title">
              产品业绩(收现):实物产品的收款总额（排除合作项目，收款方式不含卡扣）
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip>
        </div>
        <div class="bottomtext2">
          ¥{{ statisticsInfo.product_amount || 0.00 }}
        </div>
      </div><a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
      <div class="text-center">
        <div class="toptext2">基础业绩(收现)
          <a-tooltip placement="top">
            <template slot="title">
              基础业绩(收现):卡项（ 包括服务、 项目、 套盒、 权益卡） 产品的收款总额（ 排除合作项目， 收款方式不含卡扣）
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip>
        </div>
        <div class="bottomtext2">¥{{ statisticsInfo.base_amount || 0.00 }}</div>
      </div><a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
      <div class="text-center">
        <div class="toptext2">合作项目(收现)
          <a-tooltip placement="top">
            <template slot="title">
              合作项目(收现):合作项目的收款总额（收款方式不含卡扣）
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip>
        </div>
        <div class="bottomtext2">
          ¥{{ statisticsInfo.cooperation_amount || 0.00 }}
        </div>
      </div><a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
      <div class="text-center">
        <div class="toptext2">分红(元)</div>
        <div class="bottomtext2">
          ¥{{ statisticsInfo.dividend_total_amount || 0.00 }}
        </div>
      </div>
    </div>
    <a-row class="mgl-20 mgr-20" type="flex" justify="space-between" align="bottom">
      <a-col :span="8">
        <div>
          <a-radio-group v-model="query.status" class="lable_radiogroup" @change="query.page = 1,getList()">
            <a-radio-button :value="0"> 待审核 </a-radio-button>
            <a-radio-button :value="1"> 待汇入 </a-radio-button>
            <a-radio-button :value="2"> 已汇入 </a-radio-button>
          </a-radio-group>
          <template v-if="table.selectedRowKeys.length">
            <a-button class="mgl-12 normalbtn ant-btn-grounding ant-btn" style="height:32px;" v-if="query.status==0" @click="onOpen(1)">批量审核</a-button>
            <a-button class="mgl-12 normalbtn ant-btn-grounding ant-btn" style="height:32px;" v-if="query.status==1" @click="editAmount(1)">批量汇入</a-button>
          </template>
        </div>
      </a-col>
      <a-col :span="15" style="text-align: right">
        <a-input v-model="query.shop_search" style="width: 200px" placeholder="输入门店名称/代码查询" class="mgr-20 ant-input_2">
          <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
        </a-input>
        <!-- <a-select class="mgr-20 width-min-164" v-model="query.plan_id" placeholder="方案名称">
          <i slot="suffixIcon" class="meiye-icon meiye-xiala" style="font-size: 12px; "></i>
          <a-select-option v-for="it in shopList" :key="it.id" :value="it.id">
            {{ it.scheme_name }}
          </a-select-option>
        </a-select> -->
        <a-button type="primary" style="height:32px;" @click="query.page = 1,getList()">查询</a-button>
        <a-button class="mgl-12" style="height:32px;" @click="onExport"><a-icon type="upload" />导出</a-button>

      </a-col>
    </a-row>
    <div class="mgb-12 mgt-12">
      <table-filter :flitList="flitList" :isAllLength="false" :isShowAll="true" @transferson="clearfliterSingle"></table-filter>
    </div>
    <a-table class="table-manage flex-1" :scroll="{ x: '100%',y:$utils.getfullHeight(550)}" :row-selection="{
        selectedRowKeys: table.selectedRowKeys,
        onChange: onSelectChange,
      }" @change="tableChanged" :pagination="table.pagination" row-key="id" :loading="table.loading" :columns="config.brokerage.columns_a" :data-source="table.tableData">
      <template slot="_plan">
        <div class="cursor-pointer">
          <a-popover :title="null" v-model="visiblePlan" trigger="click" placement="bottomLeft">
            <template slot="content">
              <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query.plan_id" @change="visiblePlan=false,query.page = 1,getList()" placeholder="全部方案">
                <a-select-option v-for="it in shopList" :key="it.id" :value="it.id" :label="it.scheme_name">
                  {{ it.scheme_name }}
                </a-select-option>
              </a-select>
            </template>
            <span>
              <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-fangan"></i>
              合伙人方案
              <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_10" slot-scope="action, record">
        <a-tooltip placement="top">
          <template slot="title">
            {{record.scheme_name || '--'}}
          </template>
          {{record.scheme_name || '--'}}
        </a-tooltip>
      </template>
      <template slot="_8" slot-scope="action, record">
        <a-button type="primary" v-if="record.status == 0" size="small" class="mgr-12 small_primary" @click="onOpen(2,record)">审核</a-button>
        <a-button type="primary" v-if="record.status == 1" size="small" class="mgr-12 small_primary" @click="editAmount(2,record)">汇入</a-button>
        <a-button size="small" v-if="record.status == 0" class="mgr-12 smallbtn" @click="editUser(record)">编辑</a-button>
        <a-button size="small" class="mgr-12 smallbtn" @click="toDetail(record)">查看</a-button>
      </template>
    </a-table>
    <a-modal class="ak-modal-table-tb" :visible="edit_modal.visible" :footer="edit_modal.type==2 ? null : undefined" @cancel="edit_modal.visible=false" :width="edit_modal.type==2?950:680">
      <template slot="title">
        <span>{{edit_modal.title}}</span>
        <span class="mgl-12" size="small" :class="edit_modal.status == 1 ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{edit_modal.status_text}}</span>
      </template>
      <a-form-model class="mgt-10" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" labelAlign="left">
        <a-form-model-item label="合伙人方案">
          {{edit_modal.data.scheme_name}}
        </a-form-model-item>
        <a-form-model-item label="订单产生时间">
          {{edit_modal.data.created_at}}
        </a-form-model-item>
        <a-form-model-item label="门店分红订单编号">
          {{edit_modal.data.sn}}
        </a-form-model-item>
        <h2 class="twoh2 mgt-12">
          <span class="tpointer"></span>
          <span class="ttxt">门店分红项目</span>
        </h2>
        <template v-if="edit_modal.type==1">
          <a-form-model-item label="统计月份" style="margin-bottom:-8px">
            {{edit_modal.data.dividend_month}}
          </a-form-model-item>
          <a-form-model-item class="mgt-20" v-for="v in edit_modal.data.dividend_order_rule" :key="v.dividend_business_text" :label="v.dividend_business_text">
            <a-input type="number" style="width:255px" v-model="v.amount" suffix="元" />
          </a-form-model-item>
        </template>
        <template v-if="edit_modal.type==2">
          <div class="item_cards mgb-20">
            <template v-for="v in edit_modal.data.dividend_order_rule">
              <div class="text-center" :key="v.dividend_business_text">
                <div class="toptext2">{{v.dividend_business_text}}</div>
                <div class="bottomtext2">¥{{v.total_amount || 0.00}}</div>
              </div>
              <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
            </template>
            <div class="text-center">
              <div class="toptext2">预计分红</div>
              <div class="bottomtext2">¥{{edit_modal.data.predict_dividend_amount || 0.00}}</div>
            </div>
            <a-divider type="vertical" style="height: 37px; background-color: #D2E6E4;" />
            <div class="text-center">
              <div class="toptext2">实际分红</div>
              <div class="bottomtext2">¥{{edit_modal.data.actual_dividend_amount || 0.00}}</div>
            </div>
          </div>
        </template>
      </a-form-model>
      <template slot="footer">
        <span style="font-size: 16px;color: rgba(45,56,53,0.8);">预计分红 </span>
        <span class="theme-color mgr-20" style="position: relative;top: 4px;font-size: 28px;font-family: DINAlternate-Bold, DINAlternate;font-weight: bold;">¥{{edit_modal.data.predict_dividend_amount}}</span>
        <a-button @click="edit_modal.visible=false">
          取消
        </a-button>
        <a-button type="primary" @click="handleOkEdit">
          确认
        </a-button>
      </template>
    </a-modal>
    <a-modal class="ak-modal-table-tb" :title="modal.title" :visible="modal.visible" @ok="handleOk" @cancel="handleCancel">
      <a-form-model class="mgt-10" :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" labelAlign="left">
        <a-form-model-item label="订单数量">
          {{modal.num }}笔
        </a-form-model-item>
        <a-form-model-item label="通知方式">
          系统通知
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal class="ak-modal-table-tb" :title="amount_modal.title" :visible="amount_modal.visible" @ok="handleOkAmount" @cancel="amount_modal.visible=false">
      <a-form-model class="mgt-10" :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" labelAlign="left">
        <a-form-model-item label="累计汇入金额">
          <span class="theme-color" style="font-size:18px;font-weight:600">¥{{amount_modal.data.predict_dividend_amount||0}}</span>
        </a-form-model-item>
        <a-form-model-item label="订单数量">
          {{amount_modal.num }}笔
        </a-form-model-item>
        <a-form-model-item label="通知方式">
          系统通知
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 生成分红订单 -->
    <a-modal :visible="create_modal.visible" @cancel="create_modal.visible=false" :width="780">
      <template slot="title">
        <span>生成{{dividend_month}}月分红订单
          <!-- <a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
            <template slot="title">
              已完成选中月份所有收银流水分配,可生成该月分红订单；<br>
              未完成选中月份所有收银流水分配,不可生成该月分红订单
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip> -->
        </span>
      </template>
      <h2 class="twoh1 mgl-12 mgb-20">
        <span class="tpointer"></span>
        <span class="ttxt">未完成流水分配门店
          <a-tooltip placement="top" overlayClassName="spec_tooltip">
            <template slot="title">
              未完成选中月份所有收银流水分配,不可生成该月分红订单
            </template>
            <a-icon :style="{ color: '#819190' }" type="info-circle" />
          </a-tooltip>
        </span>
        </span>
      </h2>
      <!-- <div class="mgb-20" style="text-align:center">
        <a-radio-group v-model="showStatus" class="lable_radiogroup">
          <a-radio-button style="width: 220px;" :value="0"> 已完成流水分配门店 </a-radio-button>
          <a-radio-button style="width: 220px;" :value="1"> 未完成流水分配门店 </a-radio-button>
        </a-radio-group>
      </div> -->
      <div class="shop-body">
        <div class="conter flex">
          <div class="left" v-if="withBrand.length">
            <div :class="activeIndex == it.value?'item-text-active item-text':'item-text'" v-for="(it,i) in withBrand" :key="i" @click="activeIndex=it.value">
              {{ it.label }}
            </div>
          </div>
          <div class="right">
            <div class="item-text" v-for="(it,index) in withBrandList" :key="index">
              {{ it.label }}
            </div>
            <div class="text-center" style="width:100%" v-if="withBrandList.length == 0">
              <a-list :data-source="[]" />
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button @click="create_modal.visible=false">
          取消
        </a-button>
        <a-button style="width: auto;" @click="handleOkCreate(1)">
          发送分配流水提醒
        </a-button>
        <a-button style="width: auto;" type="primary" @click="handleOkCreate(2)">
          生成分红订单
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import config from "../config";
import {
  partnerFhIndex,
  partnerIndexAll,
  partnerFhExamine,
  partnerFhStatistics,
  partnerFhExport,
  partnerFhAbouchement,
  partnerFhSave,
  InstitutionProvinceShop,
  partnerFhDetail,
  partnerFhCreate, partnerFhNotify
} from "@/api/partner";
import TableFilter from '@/components/intimate/tableFilter/index'
export default {
  components: { TableFilter },
  data() {
    return {
      config,
      statisticsInfo: "",
      shopList: [],
      visiblePlan: false,
      flitList: [
        { key: '合伙人方案', value: [], paramas: 'plan_id' },
      ],
      queryStatusList: [
        {
          status: 0,
          label: "待审核",
        },
        {
          status: 1,
          label: "待汇入",
        },
        {
          status: 2,
          label: "已汇入",
        },
      ],
      showStatus: 1, isactive: false,
      activeIndex: '',
      query: {
        per_page: 10,
        page: 1,
        plan_id: undefined,
        shop_search: undefined,
        status: 0,
        dividend_month: undefined,
      },
      create_modal: {
        title: '生成分红订单',
        visible: false,
      },
      modal: {
        title: '审核',
        visible: false,
        num: 1,
        data: {
          id: '',
        }
      },
      edit_modal: {
        title: '编辑',
        status: '',
        status_text: '',
        visible: false,
        type: '1',
        data: {
          id: '',
          scheme_name: '',
          created_at: '',
          sn: '',
          actual_dividend_amount: '',
          dividend_month: '',
          predict_dividend_amount: '',
          dividend_order_rule: ''
        }
      },
      amount_modal: {
        title: '汇入',
        visible: false,
        num: 1,
        data: {
          id: '',
          predict_dividend_amount: ''
        }
      },
      table: {
        tableData: [],
        loading: false,
        selectedRowKeys: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      },
      withBrand: [],
      withBrandList: [],
      withBrandListAll: [],
    };
  },
  watch: {
    activeIndex: {
      handler(val) {
        console.log('val :>> ', val);
        console.log('this.withBrandListAll :>> ', this.withBrandListAll);
        let list = [];
        let ids = [];
        this.withBrandListAll.forEach((it, index) => {
          if (val.includes(it.value)) {
            list.push(it)
            ids.push(it.id)
          }
        })
        this.withBrandList = list;
      },
      deep: true
    },
    showStatus: {
      handler(val) {
        this.getWithBrand(val)
      },
      deep: true
    },
    'query.plan_id': {
      handler(val) {
        console.log('val :>> ', val);
        if (!val) {
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.id == val);
        if (str) {
          this.flitList[0].value = [str.scheme_name]
        }
      }
    }
  },
  created() {
    // let time = moment().format("YYYY-MM-DD HH:mm:ss");
    // console.log(time)  // 2022-07-01 10:00:16
    var now = new Date();
    var nowYear = now.getFullYear();
    var nowMonth = now.getMonth();
    console.log("nowYear :>> ", nowYear);
    console.log("nowMonth :>> ", nowMonth);
    let num = nowMonth < 10 ? '0' + nowMonth : nowMonth
    this.query.dividend_month = nowYear + "-" + num;
    this.dividend_month = nowYear + "-" + num;
    this.getList();
    this.getInit();
    partnerIndexAll().then((res) => {
      console.log(res.data, "data");
      this.shopList = res.data;
    });
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '合伙人方案', value: [], paramas: 'plan_id' },
      ]
      this.query = {
        per_page: 10,
        page: 1,
        plan_id: undefined,
        shop_search: undefined,
        status: 0,
        dividend_month: undefined,
      }
      this.getList()
    },
    getInit() {
      partnerFhStatistics({ dividend_month: this.query.dividend_month }).then(
        (res) => {
          console.log(res.data, "data");
          this.statisticsInfo = res.data;
        }
      );
    },
    // 获取门店
    getWithBrand(type = 1) {
      InstitutionProvinceShop({ dividend_month: this.dividend_month }).then(res => {
        this.withBrand = res.data.map((item, index) => {
          item.data = item.data.map(it => {
            return { ...it, value: `${index}level-1`, label: it.shop_abbreviation }
          })
          return { ...item, value: `${index}level-1`, label: item.province }
        })
        console.log('this.withBrand :>> ', this.withBrand);
        let list = []
        this.withBrand.forEach((v) => {
          list = list.concat(v.data)
        })
        console.log('list :>> ', list);
        this.activeIndex = this.withBrand[0].value
        this.withBrandListAll = list;
      })
    },
    setMonth(date, dateString) {
      this.query.dividend_month = dateString;
      // this.dividend_month = dateString;
      this.getInit();
      this.getList();
    },
    // 审核弹出层
    onOpen(type, item) {
      console.log('item :>> ', item);
      if (type == 2) {
        this.modal.title = '审核';
        this.modal.data.id = item.id;
        this.modal.num = 1
      } else {
        this.modal.title = '批量审核';
        if (this.table.selectedRowKeys.length == 0) return this.$message.warning('请选择订单！')
        this.modal.num = this.table.selectedRowKeys.length;
        this.modal.data.id = this.table.selectedRowKeys.toString();
      }
      this.modal.visible = true
    },
    handleCancel() {
      this.modal.visible = false
    },
    // 审核
    handleOk() {
      const hide = this.$message.loading('正在审核..', 0)
      partnerFhExamine(this.modal.data).then((res) => {
        this.modal.visible = false;
        this.getList()
      }).finally(r => {
        hide()
      })
    },
    // 汇入弹出层
    editAmount(type, item) {
      console.log('item :>> ', item);
      if (type == 2) {
        this.amount_modal.title = '汇入';
        this.amount_modal.data.predict_dividend_amount = item.predict_dividend_amount;
        this.amount_modal.data.id = item.id;
        this.modal.num = 1
      } else {
        this.amount_modal.title = '批量汇入';
        if (this.table.selectedRowKeys.length == 0) return this.$message.warning('请选择批量汇入的订单！')
        var money = 0;
        this.table.selectedRowKeys.forEach(num => {
          let str = this.table.tableData.find(v => v.id == num)
          let price = str.predict_dividend_amount;
          console.log('str :>> ', str);
          money = Number(money) + Number(price)
        });
        console.log('money :>> ', money);
        this.amount_modal.data.predict_dividend_amount = money.toFixed(2);
        this.amount_modal.num = this.table.selectedRowKeys.length;
        this.amount_modal.data.id = this.table.selectedRowKeys.toString();
      }
      this.amount_modal.visible = true
    },
    // 汇入
    handleOkAmount() {
      const hide = this.$message.loading('正在汇入..', 0)
      partnerFhAbouchement(this.amount_modal.data).then((res) => {
        this.amount_modal.visible = false;
        this.getList()
      }).finally(r => {
        hide()
      })
    },
    // 获取列表
    getList() {
      let self = this;
      self.table.selectedRowKeys = []
      self.table.loading = true;
      const params = {
        ...self.query,
      };
      return partnerFhIndex(params)
        .then((res) => {
          console.log(res.data, "data");
          const { data = [], total = 0 } = res.data;
          self.table.tableData = data;
          self.table.pagination = Object.assign(
            {},
            { pageSize: this.query.per_page, current: this.query.page },
            {
              total: total,
            }
          );
        })
        .finally((r) => {
          self.table.loading = false;
        });
    },
    // 导出
    onExport() {
      let self = this;
      const params = {
        ...self.query,
      };
      const hide = this.$message.loading("正在导出..", 0);
      partnerFhExport(params)
        .then((res) => {
          console.log('res :>> ', res);
          //创建一个隐藏的a链接
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/vnd.ms-excel" });  //文件流处理
          link.style.display = "none";  //去除a标签的样式
          // 设置连接
          link.href = URL.createObjectURL(blob);
          link.download = '合伙人分红订单' + self.query.dividend_month;
          document.body.appendChild(link);
          //模拟点击事件
          link.click();
          //移除创建的a标签
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
          hide();
        })
        .finally((r) => {
          hide();
          console.log("r :>> ", r);
        });
    },
    // 生成订单
    handleOkCreate(key) {
      if (key == 1) {
        const hide = this.$message.loading("正在发送通知..", 0);
        partnerFhNotify({ dividend_month: this.query.dividend_month })
          .then((res) => {
            this.$notification.success({message: '通知已发送成功'});
            this.create_modal.visible = false;
            this.query.page = 1;
            this.getList();
          })
          .finally((r) => {
            hide();
            console.log("r :>> ", r);
          });
      }
      if (key == 2) {
        const hide = this.$message.loading("正在生成订单..", 0);
        partnerFhCreate({ dividend_month: this.query.dividend_month })
          .then((res) => {
            this.$notification.success({message: '订单已生成'});
            this.create_modal.visible = false;
            this.query.page = 1;
            this.getList();
          })
          .finally((r) => {
            hide();
            console.log("r :>> ", r);
          });
      }

    },
    setCreate() {
      this.getWithBrand()
      this.create_modal.visible = true
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.table.selectedRowKeys = selectedRowKeys;
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination;
      const { field, order } = sorter;
      this.query = Object.assign(this.query, {
        per_page: Number(pageSize),
        page: Number(current),
      });
      this.getList();
    },
    //查看详情
    toDetail(item) {
      this.edit_modal.type = 2;
      partnerFhDetail({ id: item.id }).then(res => {
        this.edit_modal.title = res.data.shop?.shop_abbreviation || '--';
        this.edit_modal.status = res.data.status;
        this.edit_modal.status_text = res.data.status_text;
        this.edit_modal.data.scheme_name = item.scheme_name || '--';
        this.edit_modal.data.created_at = res.data.created_at;
        this.edit_modal.data.predict_dividend_amount = res.data.predict_dividend_amount;
        this.edit_modal.data.actual_dividend_amount = res.data.actual_dividend_amount;
        this.edit_modal.data.dividend_month = res.data.dividend_month;
        this.edit_modal.data.sn = res.data.sn;
        this.edit_modal.data.id = res.data.id;
        this.edit_modal.data.dividend_order_rule = res.data.orderRuleData;
        this.edit_modal.visible = true
      })
    },
    // 编辑
    editUser(item) {
      this.edit_modal.type = 1;
      partnerFhDetail({ id: item.id }).then(res => {
        this.edit_modal.title = res.data.shop?.shop_abbreviation || '--';
        this.edit_modal.status = res.data.status;
        this.edit_modal.status_text = res.data.status_text;
        this.edit_modal.data.scheme_name = item.scheme_name || '--';
        this.edit_modal.data.created_at = res.data.created_at;
        this.edit_modal.data.predict_dividend_amount = res.data.predict_dividend_amount;
        this.edit_modal.data.actual_dividend_amount = res.data.actual_dividend_amount;
        this.edit_modal.data.dividend_month = res.data.dividend_month;
        this.edit_modal.data.sn = res.data.sn;
        this.edit_modal.data.id = res.data.id;
        this.edit_modal.data.dividend_order_rule = res.data.orderRuleData.map(val => {
          val.amount = val.total_amount;
          return val
        });;
        this.edit_modal.visible = true
      })
    },
    handleOkEdit() {
      const hide = this.$message.loading('正在提交..', 0)
      partnerFhSave(this.edit_modal.data).then((res) => {
        this.edit_modal.visible = false
        this.getList()
      }).finally(r => {
        hide()
      })
    }
  },
};
</script>
<style lang="less" scoped>
.personnelfiles {
  background: #fff;
  padding: 20px 0px;
  margin: 0 0 18px 0 !important;
}
.twoh3 {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 6px;
    height: 6px;
    background: #aed6d8;
    border-radius: 1px;
    margin-right: 5px;
  }
  .ttxt {
    height: 18px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
    margin-right: 12px;
  }
}
.item_cards {
  background: #fafcfc;
  border-radius: 8px;
  border: 1px solid @borderColorBase;
  display: flex;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 28px;
  padding-bottom: 22px;
  justify-content: space-evenly;
  .toptext2 {
    font-size: 14px;
    font-weight: 400;
    color: #4d5e59;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .bottomtext2 {
    color: @baseColor11;
    font-size: 22px;
    height: 28px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    line-height: 28px;
  }
}
/deep/ .ant-tag {
  color: @primaryColor;
  border: 1px solid @primaryColor;
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: baseline;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
  }
}
.twoh2 {
  display: flex;
  margin-bottom: 7px;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin: 6px 8px 0 0;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 22px;
  }
}
/deep/.ant-row-flex-bottom {
  align-items: center;
}
.shop-body {
  background: #fafcfc;
  border-radius: 6px;
  border: 1px solid @borderColorBase;
  margin-left: 12px;
  .conter {
    .left {
      width: 138px;
      height: 371px;
      padding: 12px;
      overflow-y: auto;
      overflow-x: hidden;
      border-right: 1px solid @borderColorBase;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      .item-text {
        padding: 5px 8px;
        margin-bottom: 10px;
        display: block;
      }
      .item-text:hover {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
      .item-text-active {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
    }
    .right {
      padding: 12px 27px;
      overflow-y: auto;
      height: 371px;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      .item-text {
        padding: 5px 8px;
        margin-bottom: 10px;
        width: 33%;
      }
    }
  }
}
/deep/.ant-modal-body {
  padding-bottom: 0;
}
</style>
