/**
 * 全局的业务属性常量在这里定义，页面通过 $const 引用
 */

export const STATUS = [{
  id: 1, name: '状态一'
}, {
  id: 1, name: '状态二'
}, {
  id: 1, name: '状态三'
}]
