import axios from "axios";
import notification from "ant-design-vue/es/notification";
// import config from '@/config/config'
const BASE_URL = process.env.VUE_APP_API_BASE_URL2;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('Access-Token')
// 创建 axios 实例
const instance = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL2,
  timeout: 32000, // 请求超时时间
});
const onOk = () => {
  localStorage.clear();
  location.href = `${process.env.VUE_APP_ROUTER_BASE_URL}user/login`;
};
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    console.log(error.response);
    const data = error.response.data;
    const description = data.error && data.error.message;
    // 从 localstorage 获取 token
    const token = localStorage.getItem('Access-Token')
    if (error.response.status === 404) {
      return notification.error({
        message: "请求路径错误",
        description: error.response.request.responseURL,
      });
    }
    if (error.response.status === 403) {
      notification.error({
        message: "拒绝访问",
        description: description,
      });
    }
    if (error.response.status === 500) {
      notification.error({
        message: "服务器错误，请稍后重试！",
        description: description,
      });
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: "登录状态失效",
        description: "未授权，请重新登录",
      });
      // setTimeout(onOk, 3000);
    } else {
      // console.log(data)
      notification.error({
        message: `错误${data.error.code}！`,
        description: description,
      });
    }
  }
  return Promise.reject(error);
};

// response interceptor
instance.interceptors.response.use((config) => {
  const token = localStorage.getItem('Access-Token') || localStorage.getItem('token')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改 
  
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config;
}, errorHandler);

// response interceptor
instance.interceptors.response.use((response) => {
  const result = response.data;
  const errorMsg =
    (result.error && result.error.message) ||
    result.message ||
    "请求出现错误，请稍后再试";
  if (result.status !== "success") {
    if (result?.error?.code == 401) {
      notification.error({
        message: "登录状态失效",
        description: "未授权，请重新登录",
      });
      // setTimeout(onOk, 3000);
    } else {
      notification["error"]({
        message: "错误",
        description: errorMsg,
        duration: 4,
      });
      return Promise.reject(new Error(errorMsg));
    }
  } else {
    const { code, message } = response.data.data;
    console.log("response :>> ", response);
    if (response.data.status == "success") {
      return response.data;
    } else {
      if (code || message) {
        notification["success"]({
          message: "成功",
          description: code || message,
          duration: 2.4,
        });
      }
    }
  }
}, errorHandler);

export default instance;
