<!--
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-11 10:26:28
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-22 05:07:26
-->
<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;position: relative;">
      <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
        <div type="left" class="leftarrow" :class="imgflag?'leftarrow_hover':''" @mouseenter="imgflag=true" @mouseleave="imgflag=false">
          <i class="meiye-icon meiye-fanghui"></i>
        </div>
        <span @mouseenter="imgflag=true" @mouseleave="imgflag=false">{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'查看':'编辑' }}</span>
    </div>

    <div class="height100 flex flex-column">
      <div class="padding-wai">
        <div class="padding-item">
          <div class="detail-title">
            <a-avatar shape="square" class="img" :src="detailInfo.img" />
            <div>
              <div style="margin-bottom:4px">
                <span class="title">{{detailInfo?.partner?.name || '--'}} </span>
                <span style="margin-left:8px" size="small" :class="detailInfo.statusText != '待结算' ? 'mrj-span-tag-green' : 'mrj-span-tag-red'">{{detailInfo.statusText}}</span>
              </div>
              <div class="text" style="color:#2D3835"><i class="meiye-icon meiye-shouji" style="color:#819190"></i> {{detailInfo?.partner?.phone || '--'}} <a-divider type="vertical" style="height: 9px; background-color: #DDE8E7;" /><span>{{detailInfo.shop?.shop_abbreviation || '--'}}</span></div>
            </div>
          </div>
          <a-divider />
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt">基础信息</span>
          </h2>
          <a-row class="mgl-10">
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">佣金订单编号</span>
                <span class="rText">{{detailInfo.sn || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">结算时间</span>
                <span class="rText">{{detailInfo.settlement_time || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">结算方式</span>
                <span class="rText">{{detailInfo.settlementTypeText || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">合伙人方案</span>
                <span class="rText">{{detailInfo.partnerPlan?.scheme_name || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">银行卡</span>
                <span class="rText">{{detailInfo.bankCard?.bank_card_no || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">开户行</span>
                <span class="rText">{{detailInfo.bankCard?.bank_name || '--'}}</span>
              </div>
            </a-col>
            <a-col :span="8">
              <div class="item-r-l">
                <span class="lText">持卡人</span>
                <span class="rText">{{detailInfo.bankCard?.name || '--'}}</span>
              </div>
            </a-col>
          </a-row>
          <h2 class="twoh2">
            <span class="tpointer"></span>
            <span class="ttxt">佣金信息</span>
          </h2>
          <div class="mgl-10">
            <a-row>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">订单客户</span>
                  <span class="rText">{{detailInfo.payUser?.realname || '--'}} {{detailInfo.payUser?.mobile || '--'}}</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">收款金额</span>
                  <span class="r theme-color" style="font-weight: 500;font-family: PingFangSC-Medium, PingFang SC;"> ¥{{detailInfo.total_amount || '0'}}</span>
                </div>
              </a-col>
              <a-col :span="8">
                <div class="item-r-l">
                  <span class="lText">收款时间</span>
                  <span class="rText">{{detailInfo.pay_time || '--'}}</span>
                </div>
              </a-col>
            </a-row>

            <h2 class="twoh1 mgt-12">
              <span class="tpointer"></span>
              <span class="ttxt">佣金明细<span class="mgl-12 mgr-12" style="color:#D2D6D5">|</span>{{ detailInfo.type}}
                <a-button v-if="$route.query.type==2" @click="editType()" class="smallbtn-icon"><span class="meiye-icon meiye-moren"></span></a-button>
              </span>
            </h2>
            <a-table class="mgb-30 mgt-10 detailsTable" :loading="table_loading" :pagination="false" row-key="id" size="middle" :columns="$route.query.type==1?config.bonus.detail_columns_money:config.bonus.detail_columns_money_two" :data-source="detailInfo.commissionOrderDetail">
              <template slot="_3" slot-scope="text, record, index">
                {{setJion(record.payDetail)}}
              </template>
              <template slot="_4" slot-scope="text, record, index">
                ¥{{record.amount || 0.00}}
                <a-button v-if="$route.query.type==2" @click="editAmount(record,index)" class="smallbtn-icon"><span class="meiye-icon meiye-moren"></span></a-button>
              </template>
            </a-table>
            <h2 class="twoh1">
              <span class="tpointer"></span>
              <span class="ttxt">支付明细</span>
            </h2>
            <div class="y-row">
              <div class="y-flex y-header">
                <div class="m-w-200">支付方式</div>
                <div class="m-w-200" v-for="(v,s) in detailInfo.payDetail" :key="s">{{v.pay_way_txt}}</div>
              </div>
              <div class="y-flex y-content">
                <div class="m-w-200">支付金额</div>
                <div class="m-w-200" v-for="(b,i) in detailInfo.payDetail" :key="i">¥{{b.money}}</div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="y-flex y-foot mgb-20 pl-20">佣金: <div class="theme-color mgr-12 mgl-12 font26">¥{{detailInfo.commission_amount || 0}}</div>
          <a-button type="primary" v-if="detailInfo.statusText == '待结算'&&$route.query.type!=2" @click="onOpen">结算</a-button>
          <a-button type="primary" v-if="$route.query.type==2" @click="saveChage">保存</a-button>
          <a-button class="mgl-20" v-if="$route.query.type==2" @click="onCancellation">作废</a-button>
          <a-button class="mgl-20" v-if="$route.query.type==2" @click="changeRoute">取消</a-button>
        </h5>
      </div>

      <a-modal  class="ak-modal-table-tb" :title="modal.title" :visible="modal.visible" @ok="handleOk" @cancel="handleCancel">
        <a-form-model class="mgt-10" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" labelAlign="left">
          <a-form-model-item label="合伙人">{{modal.tableList?.[0]?.partner || '--' }}</a-form-model-item>
          <a-form-model-item label="银行卡号">{{modal.tableList?.[0]?.bank_card_no || '--' }}</a-form-model-item>
          <a-form-model-item label="开户行">{{modal.tableList?.[0]?.bank_name || '--' }}</a-form-model-item>
          <a-form-model-item label="持卡人">{{modal.tableList?.[0]?.name || '--' }}</a-form-model-item>
          <a-form-model-item label="结算佣金金额">
            <span class="theme-color" style="font-size:18px;font-weight:600"> ¥{{modal.data.commission_amount}}</span>
          </a-form-model-item>
          <a-form-model-item label="订单数量">
            {{modal.num}}笔
          </a-form-model-item>
          <div class="ant-row ant-form-item">
            <div class="ant-col ant-col-4 ant-form-item-label ant-form-item-label-left">
              结算方式<a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
                <template slot="title">
                  线上:通过银行转账方式结算合伙人佣金 <br>
                  线下:通过其他方式结算合伙人佣金
                </template>
                <a-icon :style="{ color: '#819190',marginLeft:'4px' }" type="info-circle" />
              </a-tooltip>
            </div>
            <div class="ant-col ant-col-16 ant-form-item-control-wrapper">
              <div class="ant-form-item-control">
                <a-radio-group v-model="modal.data.settlement_type">
                  <a-radio :value="1">
                    线上
                  </a-radio>
                  <a-radio :value="2">
                    线下
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>
          <a-form-model-item label="通知方式">
            系统通知
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal  class="ak-modal-table-tb" :title="amount_modal.title" :visible="amount_modal.visible" @ok="handleOkAmount" @cancel="amount_modal.visible=false">
        <a-form-model class="mgt-10" :label-col="{ span: 3 }" :wrapper-col="{ span: 17 }" labelAlign="left">
          <a-form-model-item label="修改金额" required>
            <a-input  type="number" class="mgb-20 ant-input_1" style="width:187px" v-model="amount_modal.data.money" suffix="元" />
          </a-form-model-item>
          <a-form-model-item label="修改原因" style="margin-bottom:0px">
            <a-textarea placeholder="请输入修改原因，100字已内" v-model="amount_modal.data.content" :maxLength="100" allow-clear />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal  class="ak-modal-table-tb" :title="discard_modal.title" :visible="discard_modal.visible" @ok="handleOkDiscard" @cancel="discard_modal.visible=false">
        <a-form-model class="mgt-10" :label-col="{ span: 3 }" :wrapper-col="{ span: 17 }" labelAlign="left">
          <a-form-model-item label="修改原因">
            <a-textarea placeholder="请输入修改原因，100字已内" v-model="discard_modal.data.cancellation_remark" :maxLength="100" allow-clear />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <a-modal  class="ak-modal-table-tb" :title="type_modal.title" :visible="type_modal.visible" @ok="handleOkType" @cancel="type_modal.visible=false">
        <a-form-model class="mgt-10" :label-col="{ span: 3 }" :wrapper-col="{ span: 17 }" labelAlign="left">
          <a-form-model-item label="修改原因">
            <a-select v-model="type_modal.data.type" style="width: 120px">
              <a-select-option :value="1">
                首购
              </a-select-option>
              <a-select-option :value="2">
                复购
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import storage from 'store'
import config from '../config'
import Msg from '@/components/mrj-menu/msg'
import { partnerProfitDetail, partnerProfitSettle, partnerProfitDiscard, partnerProfitDetailupdate, partnerUpdatetype,partnerGetbankcard } from '@/api/partner'
export default {
  inject: ['reload'],
  data() {
    return {
      config,
      detailInfo: {},
      table_loading: false,
      modal: {
        title: '结算',
        visible: false,
        num: 1,
        form: '',
        tableList: [],
        data: {
          id: '',
          settlement_type: 1,
          commission_amount: '',
        }
      },
      amount_modal: {
        title: '修改金额',
        visible: false,
        data: {
          id: '',
          index: '',
          money: '',
          content: '',
        }
      },
      discard_modal: {
        title: '作废订单',
        visible: false,
        data: {
          id: '',
          cancellation_remark: '',
        }
      },
      type_modal: {
        title: '修改佣金订单类型',
        visible: false,
        data: {
          id: '',
          type: '',
        }
      },
      imgflag: false,
    }
  },
  created() {
    let self = this
    const { id } = self.$route.query
    const data = {
      id
    }
    partnerProfitDetail(data).then(res => {
      self.detailInfo = res.data;
    })
  },
  methods: {
    Getbankcard() {
      partnerGetbankcard({ id: this.modal.data.id }).then((res) => {
        this.modal.tableList = res.data.list;
      })
    },
    // 编辑首购/复购
    editType() {
      this.type_modal.data.id = this.detailInfo.id;
      this.type_modal.data.type = this.detailInfo.type == "首购" ? 1 : 2;
      this.type_modal.visible = true
    },
    handleOkType() {
      let self = this
      partnerUpdatetype(self.type_modal.data).then((res) => {
        if (res.data.is_refresh == 1) {
          const { id } = self.$route.query
          const data = {
            id
          }
          partnerProfitDetail(data).then(rec => {
            self.detailInfo = rec.data;
          })
        } else {
          self.detailInfo.type = self.type_modal.data.type == 1 ? '首购' : '复购';
          self.detailInfo.commissionOrderDetail = res.data.commissionOrderDetail;
          self.detailInfo.commission_amount = res.data.commission_amount;
        }
        self.type_modal.visible = false;
      }).finally(r => {
        console.log('r :>> ', r);
      })
    },
    // 编辑佣金弹出层
    editAmount(item, index) {
      this.amount_modal.data.index = index;
      this.amount_modal.data.id = item.id;
      this.amount_modal.data.money = item.amount;
      this.amount_modal.visible = true
    },
    // 编辑佣金
    handleOkAmount() {
      let self = this
      self.detailInfo.commissionOrderDetail[self.amount_modal.data.index].amount = self.amount_modal.data.money;
      self.detailInfo.commissionOrderDetail[self.amount_modal.data.index].content = self.amount_modal.data.content;
      self.amount_modal.visible = false

    },
    // 保存
    saveChage() {
      const arr = this.detailInfo.commissionOrderDetail
      const updateData = arr.map(val => {
        val.id = val.id;
        val.money = val.amount;
        val.content = val.content;
        delete val.payDetail;
        delete val.product_id;
        delete val.product_name;
        delete val.order_no;
        delete val.amount;
        return val
      })
      console.log('this.detailInfo.type :>> ', this.detailInfo.type);
      let type = this.detailInfo.type == '首购' ? 1 : 2;
      partnerProfitDetailupdate({ updateData: updateData, id: this.$route.query.id, type: type }).then((res) => {
        this.changeRoute()
      }).finally(r => {
        console.log('r :>> ', r);
      })
    },
    setJion(v) {
      let arr = []
      v.forEach(element => {
        let str = element.pay_way_txt + ': ¥' + element.money;
        arr.push(str)
      });
      return arr.join(' ')
    },
    // 作废
    onCancellation() {
      this.discard_modal.data.id = this.detailInfo.id;
      this.discard_modal.visible = true;
    },
    handleOkDiscard() {
      const hide = this.$message.loading('正在提交..', 0)
      partnerProfitDiscard(this.discard_modal.data).then((res) => {
        this.changeRoute()
      }).finally(r => {
        hide()
      })
    },
    onOpen() {
      this.modal.title = '结算';
      this.modal.data.commission_amount = this.detailInfo.commission_amount;
      this.modal.data.id = this.detailInfo.id;
      this.modal.form = this.detailInfo;
      this.modal.num = 1
      this.Getbankcard();
      this.modal.visible = true
    },
    handleCancel() {
      this.modal.visible = false
    },
    handleOk() {
      const hide = this.$message.loading('正在结算..', 0)
      partnerProfitSettle(this.modal.data).then((res) => {
        this.changeRoute()
      }).finally(r => {
        hide()
      })
    },
    changeRoute() { 
      this.$emit('onCancel') 
      console.log('this.$route.fullpath :>> ', this.$route.fullPath);
      let arr = this.$route.fullPath.split('/')
      console.log(arr);
      if (arr.length > 3) {
        let newArr = arr.slice(0, -1)
        let router = newArr.join('/')
        this.$router.push({ path: router });
      } else {
        this.$router.push({ path: this.$route.path }); 
      }
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
    },

  },
  watch: {
    $route() { 
      const { path, fullPath, meta, query, params, matched } = this.$route
    }
  }
};
</script>

<style scoped lang="less">
.padding-wai {
  margin: 18px;
  height: calc(100vh - 138px);
  background: #fff;
  position: relative;
  overflow-y: auto;
  padding-bottom: 100px;
}
.padding-item {
  padding: 40px 0 0 35px;
}
.twoh2 {
  display: flex;
  margin-top: 20px;
  align-items: center;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    color: @fontColor1;
    line-height: 25px;
  }
}
.twoh1 {
  display: flex;
  margin-bottom: 12px;
  align-items: baseline;
  .tpointer {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 1px;
    margin-right: 8px;
  }
  .ttxt {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: @fontColor1;
    line-height: 20px;
  }
}
.item-r-l {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  margin-bottom: 15px;
  margin-top: 15px;
  display: flex;
  .rText {
    color: @fontColor1;
  }
  .lText {
    color: rgba(45, 56, 53, 0.8);
    width: 123px;
    display: block;
  }
}
.y-flex {
  display: flex;
  align-items: center;
}
.y-row {
  font-size: 14px;
  font-weight: 400;
  color: @fontColor3;
  .y-header {
    height: 44px;
    line-height: 44px;
    padding-left: 24px;
    font-weight: 500;
    color: @fontColor1;
    border-top: 1px solid #a6dad7;
    background: rgba(236, 244, 243, 0.41);
    box-shadow: inset 0px -2px 0px 0px rgba(193, 212, 211, 0.31);
  }
  .y-content {
    height: 50px;
    padding-left: 24px;
    font-weight: 400;
    line-height: 50px;
    background: rgba(170, 170, 170, 0);
    border-bottom: 1px solid #e8edec;
  }
  .m-w-200 {
    min-width: 200px;
  }
}
.font26 {
  font-size: 26px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
}
.detail-title {
  display: flex;
  align-items: center;
  .img {
    width: 60px;
    height: 60px;
    background: #cbe3e1;
    border-radius: 8px;
    margin-right: 18px;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: @fontColor1;
  }
  .text {
    font-size: 14px;
    font-weight: 400;
    color: @fontColor1;
  }
}
.pl-20 {
  margin-top: 11px;
  border-top: 1px solid #d8eceb;
  padding-left: 42px;
  padding-top: 20px;
}
/deep/ .detailsTable .ant-table .ant-table-thead > tr > th {
  padding: 1px 8px !important;
}
/deep/
  .detailsTable
  .ant-table
  .ant-table-thead
  .ant-table-header-column
  .ant-table-column-title {
  color: @fontColor1 !important;
  font-weight: 500;
}
/deep/ .detailsTable .ant-table .ant-table-tbody > tr > td {
  // padding: 0px 0 0 0px !important;
  color: @fontColor3 !important;
  font-weight: 400;
  height: 54px;
}
/deep/ .detailsTable .ant-table-row > td:nth-child(1),
/deep/ .detailsTable .ant-table-thead > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table-tbody > tr:first-child > th:first-child {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table .ant-table-tbody > tr:hover {
  td {
    background-color: #fff !important;
  }
}
.y-foot {
  position: fixed;
  bottom: -1px;
  padding-bottom: 16px;
  padding-top: 16px;
  background: #fff;
  width: calc(100% - 217px);
}
</style>
