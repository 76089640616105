<template>
  <div class="hehuoren">
    <!-- 全平台响应式侧边栏之 APP -->
    <a-layout-sider
      class="reset-inner-page-tags-sider ant-sider-88"
      :collapsed="collapsed"
      :collapsible="false"
      breakpoint="md"
      collapsed-width="0"
      :tigger="null"
      @collapse="onCollapse"
      @breakpoint="onBreakpoint"
      style="padding-top: 11px;width: 74px!important;min-width: 74px!important;max-width: 74px!important;display:block;"
    >
      <a-tabs
        :activeKey="activeKey"
        @change="handleTabsChanged"
        :default-active-key="tabs[0].id"
        tab-position="left"
        class="ant-tabs-left-no-ctn default_tabs"
      >
        <a-tab-pane
          v-for="it in tabs"
          :key="it.id"
          :tab="it.label"
        ></a-tab-pane>
      </a-tabs>
    </a-layout-sider>
  </div>
</template>

<script> 
export default {
  model: {
    prop: 'activeKey',
    event: 'change'
  },
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
    activeKey: {
      type: String,
    }
  },
  data() {
    return {
      collapsed: false,
      showDrawer: false,
      drawerStatus: false,
    }
  },
  methods: {
    handleTabsChanged(rec, tabs = this.tabs) {
      console.log('tabs :>> ', tabs);
      this.$emit('change', rec)
      this.$nextTick(() => {
        this.onHandleTabsChanged(rec, tabs)
      })
    },
    onHandleTabsChanged(id, tabs, c_offset = 0) {
      const eleIdwWrap = tabs.map((it) => {
        return document.getElementById(it.id)
      })
      const wrapEl = document.getElementById('mrj-scroll-main')
      let total = eleIdwWrap.map((it) => it.offsetHeight).reduce((a, b) => a + b, 0)
      let perForTabsHeight_100 = eleIdwWrap.map((it) => {
        const cur = it.offsetHeight
        return Number.parseInt((cur / total).toFixed(2) * 100)
      })
      let perTabsHeightReduce = perForTabsHeight_100.map((it, ind) => {
        return perForTabsHeight_100.slice(0, ind + 1).reduce((a, b) => a + b, 0)
      })
      let index = tabs.findIndex((it) => it.id == id)
      let curPer = perTabsHeightReduce[index] || 0
      let height = (curPer * (wrapEl.scrollHeight - wrapEl.clientHeight - c_offset)) / 100
      wrapEl.scrollTo({
        top: eleIdwWrap[index].offsetTop - eleIdwWrap[0].offsetTop,
        behavior: 'smooth',
      })
      wrapEl.scrollTop = eleIdwWrap[index].offsetTop - eleIdwWrap[0].offsetTop
      let jumpEle = document.getElementById(id)
      if (!id || !jumpEle) {
        console.warn('获取DOM错误！')
        return
      }
      jumpEle.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    },
    onCollapse(collapsed, type) {
      console.log(collapsed, type)
    },
    onBreakpoint(broken) {
      if (broken) {
        this.showDrawer = true
        this.$nextTick(() => {
          this.drawerStatus = false
          this.collapsed = false
        })
      } else {
        this.showDrawer = false
        this.$nextTick(() => {
          this.drawerStatus = false
          this.collapsed = false
        })
      }
    },
  }

}
</script>

<style lang="less" scoped>
.hehuoren {
  .reset-inner-page-tags-sider {
    height: 100% !important;
    background: #f4f5f7;
  }
  .mrj-main-ctn-sider-tags {
    background-color: @primary-color;
    color: @layout-sider-background-light;
    border-radius: 5px 0 0 5px;
    line-height: 40px;
    font-size: 22px;
    width: 40px;
    height: 40px;
    //   box-shadow: -2px 0 8px @shadow-color;
  }

  /deep/ .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    // padding: 8px 8px 8px 8px;
    white-space: break-spaces;
    padding: 0px 8px;
    height: 28px !important;
    font-size: 14px !important;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: @fontColor4;

    &::before {
      display: none;
    }
  } 
  /deep/ .ant-tabs-nav .ant-tabs-tab {
    // color: #819190;
  }
  /deep/.ant-tabs-nav .ant-tabs-tab-active {
    color: @baseColor11;
  }
  /deep/.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
  .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    width: 2px;
  }
  /deep/.ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    right: 1px;
    background: @baseColor11 !important;
  }
  /deep/.ant-tabs .ant-tabs-left-bar {
    border-right: 1px solid #bfd6d4;
  }
}
/deep/ .hehuoren .ant-tabs-left-no-ctn .ant-tabs-tab::before {
  display: none;
}
/deep/ .hehuoren .ant-tabs-left-no-ctn .ant-tabs-tab-active::before {
  display: none;
}
.hehuoren .reset-inner-page-tags-sider {
  /deep/ .ant-tabs-left-no-ctn {
    /deep/ .ant-tabs-left-bar {
      border-right: 2px solid #bfd6d4;
      float: left;
      margin-right: -1px;
      margin-bottom: 0;
      height: 100%;
      border-bottom: 0;
    }
    /deep/ .ant-tabs-tab-active::before {
      display: none;
    }
    /deep/ .ant-tabs-tab::after {
      display: none;
    }
    // width: 74px;
    /deep/ .ant-tabs-tab {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .ant-tabs-bar {
      height: auto !important;
      padding-left: 0;
      padding-top: 0;
      background: transparent !important;
      border-bottom: none;
    }
  }
}
</style>
