<template>
  <div
    :class="wrpCls"
    style="margin-right: 0"
    class="flex align-item-center justify-end"
  >
    <!-- <a-dropdown placement="bottomRight">
      <a class="bulb" @click="(e) => e.preventDefault()">
        <img v-if="themeColor" src="https://attachment.shenglisige.com/images/icon/drak.svg" style="width: 14px" />
        <img v-else src="https://attachment.shenglisige.com/images/icon/light.svg" />
      </a>
      <a-menu slot="overlay">
        <a-menu-item>
          <span @click="handleMenuTheme('')">亮色模式  <a-icon type="check" v-if="!themeColor" /></span>
        </a-menu-item>
        <a-menu-item disabled>
          <span @click="handleMenuTheme('dark')">暗黑模式 <a-icon type="check" v-if="themeColor" /></span>
        </a-menu-item>
        <a-menu-item disabled> 跟随系统</a-menu-item>
      </a-menu>
    </a-dropdown> -->
    <a-tooltip placement="bottom">
      <template slot="title">
        <span style="font-size:14px">下载</span>
      </template>
      <span
        v-if="false"
        class="cursor-pointer flex justify-center align-item-center media-768 header-btn"
        @click="$router.push({ path: '/download' })"
      >
        <i
          class="meiye-icon meiye-xiazai"
          style="font-size:14px"
        ></i>
      </span>
      <!-- <a
        class="header-right-content-btn flex justify-center align-item-center media-768"
        @click="$router.push({ path: '/download' })"
      >
      </a> -->
    </a-tooltip>
    <!-- <NoticeIcon class="media-1200" /> -->
    <div style="width: 68px">
      <avatar-dropdown
        :menu="showMenu"
        :current-user="currentUser"
        :class="prefixCls"
        style="padding: 0 12px 0 24px; display: block"
      />
    </div>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import AvatarDropdown from './AvatarDropdown'
// import NoticeIcon from '@/components/NoticeIcon'
import storage from 'store'
export default {
  inject: ['reload'],
  name: 'RightContent',
  components: {
    AvatarDropdown,
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action',
    },
    isMobile: {
      type: Boolean,
      default: () => false,
    },
    topMenu: {
      type: Boolean,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      searchActive: false,
    },
  },
  data() {
    return {
      showMenu: true,
      // themeColor: storage.get('global_brand_obj').theme_color,
      currentUser: storage.get('staff_info'),
    }
  },
  computed: {
    // ...mapGetters(['nickname', 'avatar']),
    wrpCls() {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${this.isMobile || !this.topMenu ? 'light' : this.theme}`]: true,
      }
    },
  },
  watch: {
    avatar(newVal) {
      this.currentUser.staff_avatar = newVal
    },
    nickname(newVal) {
      this.currentUser.staff_realname = newVal
    },
  },
  mounted() {
    const { staff_avatar, staff_realname } = this
    // setTimeout(() => {
    //   this.currentUser = {
    //     // staff_avatar: staff_avatar,
    //     // staff_realname: staff_realname,
    //   }
    // }, 1500)
  },
  methods: {
    handleMenuTheme(theme) {
      let list = storage.get('global_brand_obj') || {}
      list.theme_color = theme
      storage.set('global_brand_obj', list)
      this.reload()
      this.$Common.refreshAdminInfo(list.brand_id)
      // this.$router.push({ path: '/overview' });
    },
  },
}
</script>
<style lang="less" scoped>
.bulb {
  display: block;
  margin-right: 30px;
  color: #fff;
}
.header-btn {
  display: block;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  color: @topnavdowniconColor;
  background: @topnavdownbgColor;
  border-radius: 6px;
  border: 1px solid @topnavdownborderColor;
}
.header-btn:hover {
  background: @topnavdownbgHColor;
  border: 1px solid @topnavdownHborderColor;
}
.header-btn:active {
  background: @topnavdownbgAColor;
  border: 1px solid @topnavdownAborderColor;
}
.header-right-content-btn {
  display: block;
  width: 33px;
  height: 29px;
  // text-align: center;
  // background: #6C8DF5;
  // border-radius: 6px;
  // border: 1px solid #3E63D9;
  // box-shadow: none;
  // line-height: 30px;
  // color: #FFFFFF;
  // font-size: 14px;
  //background: url(../../assets/download/down-btn-1.png) no-repeat 0 0;
  background-size: 100% 100%;
  &:hover {
    // background: url(../../assets/download/down-btn-2.png) no-repeat 0 0;
    background-size: 100% 100%;
  }
  &:active {
    //background: url(../../assets/download/down-btn-3.png) no-repeat 0 0;
    background-size: 100% 100%;
  }
}
@media (max-width: 1200px) {
  .media-1200 {
    display: none;
  }
}
@media (max-width: 768px) {
  .media-768 {
    display: none;
  }
}
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
/deep/ .ant-dropdown-menu-item {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  padding: 5px 8px;
  img {
    margin-top: -0.5px;
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
}
.mrj-layout-avatar__wrap-comp {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mrj-layout-avatar__wrap-comp .mrj-layout-avatar__wrap-comp-img {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  line-height: 64px;
  border-radius: 50%;
  // background: linear-gradient(170deg, #F6FCFF 0%, #DBE9F6 100%);
  // border: 1px solid #3E63D9;
  // & .ant-avatar {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}
.mrj-layout-avatar__wrap-comp {
  position: relative;
  & .ant-avatar {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #3e63d9;
  }
  & .mrj-layout-avatar__wrap-comp-img:hover {
    & .fuck__zhezhao-mask {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 14px;
      border: 1px solid #334b9b;
    }
  }
  & .mrj-layout-avatar__wrap-comp-img:active {
    & .fuck__zhezhao-mask {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 8;
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 14px;
      border: 1px solid #253772;
      user-select: none;
    }
  }
}
.ant-dropdown-menu-item > .anticon:first-child {
  margin-right: 5px;
  font-size: 11.5px;
  min-width: auto;
}
.ant-dropdown-menu-item {
  // color: #9FA2AF;
  margin: 0 4px;
  color: @fontColor3;
  &:hover {
    background: #ecf2fe;
    border-radius: 2px;
  }
}
</style>
