/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-04 17:17:30
 * @LastEditors: YangKX
 * @LastEditTime: 2023-05-19 14:35:41
 */
import requesthhr from "@/utils/requesthhr";
const userApi = {
  // 门店 方案
  getCurrentList: "/api/v2/partner/manager/head/institution/current/list", // 当前登录人关联的门店机构列表
  getInstitutionList: "/api/v2/partner/manager/head/archives/plan/institution/list", // 获取合伙人参与过方案的所有门店列表
  getPlanAll: "/api/v2/partner/manager/chain/plan/all", // 当前登录人关联的门店方案列表
  getPlanSearchAll: "/api/v2/partner/manager/head/archives/history/plan/search/list", // 当前登录人关联的门店方案列表

  // 集团 合伙人方案/设置
  partnerAdd: "/api/v2/partner/manager/head/plan/add", // 新增
  partnerSave: "/api/v2/partner/manager/head/plan/save", // 编辑
  partnerGenerated: "/api/v2/partner/manager/head/plan/generated", // 获取方案id
  partnerIndex: "/api/v2/partner/manager/head/plan/index", // 获取方案列表
  partnerIndexAll: "/api/v2/partner/manager/head/plan/all", // 获取所有方案列表
  partnerDetail: "/api/v2/partner/manager/head/plan/detail", // 获取方案详情
  partnerCopy: "/api/v2/partner/manager/head/plan/copy", // 方案复制
  InstitutionProvince: "/api/v2/partner/manager/head/institution/province", // 要按照省进行机构区分
  partnerInstitutionList: "/api/v2/partner/manager/head/institution/list", // 模糊搜索机构
  // 集团 合伙人收益->合伙人分红
  partnerFhIndex: "/api/v2/partner/manager/head/dividendorder/index", // 分红列表
  partnerFhDetail: "/api/v2/partner/manager/head/dividendorder/detail", // 分红详情
  partnerFhExport: "/api/v2/partner/manager/head/dividendorder/export", // 导出
  partnerFhExamine: "/api/v2/partner/manager/head/dividendorder/examine", // 审核
  partnerFhDetailList: "/api/v2/partner/manager/chain/dividendorder/detailList", // 订单详情合伙人列表
  partnerFhAbouchement:
    "/api/v2/partner/manager/head/dividendorder/abouchement", // 汇入
  partnerFhCreate: "/api/v2/partner/manager/head/dividendorder/create", // 生成订单
  InstitutionProvinceShop: "/api/v2/partner/manager/shop/unassign/province", // 未分配的门店列表
  partnerFhNotify: "/api/v2/partner/manager/shop/unassign/province/notify", // 发送通知
  partnerFhSave: "/api/v2/partner/manager/head/dividendorder/save", // 编辑
  partnerFhStatistics: "/api/v2/partner/manager/head/dividendorder/statistics", // 统计
  // 集团 合伙人收益->合伙人佣金
  partnerProfitList: "/api/v2/partner/manager/head/partnerprofit/list", // 列表
  partnerProfitSettle:
    "/api/v2/partner/manager/head/partnerprofit/settleaccounts", // 结算
  partnerProfitDetail: "/api/v2/partner/manager/head/partnerprofit/detail", // 详情
  partnerProfitDiscard: "/api/v2/partner/manager/head/partnerprofit/discard", // 废弃
  partnerProfitDetailupdate:
    "/api/v2/partner/manager/head/partnerprofit/detailupdate", // 修改明细佣金
  // 连锁 合伙人收益->合伙人分红
  partnerFhOrder: "/api/v2/partner/manager/chain/dividendorder/order", // 分红订单列表
  partnerFhPartnerList:
    "/api/v2/partner/manager/chain/dividendorder/partnerlist", // 合伙人订单列表
  partnerFhChainDetailList:
    "/api/v2/partner/manager/chain/dividendorder/detaillist", // 订单详情合伙人列表
  partnerFhChainDetail: "/api/v2/partner/manager/chain/dividendorder/detail", // 门店分红订单详情
  partnerFhChainSave: "/api/v2/partner/manager/chain/dividendorder/save", // 修改实际分红金额
  partnerFhChainSettleDividend:
    "/api/v2/partner/manager/chain/dividendorder/settledividend", // 结算合伙人订单
  partnerFhChainCreateDetail:
    "/api/v2/partner/manager/chain/dividendorder/createdetail", // (2023/04/13新增)合伙人生成分红订单详情
  partnerFhChainMonthDetail:
    "/api/v2/partner/manager/chain/dividendorder/monthdetail", // (2023/04/13新增)门店方案统计月份 详情
  partnerFhChainCreate: "/api/v2/partner/manager/chain/dividendorder/create", // (2023/04/13新增)生成合伙人订单
  // 集团 合伙人档案
  partnerArchivesList: "/api/v2/partner/manager/head/archives/list", // 列表
  partnerArchivesDetail: "/api/v2/partner/manager/head/archives/detail", // 详情
  partnerArchivesInviter: "/api/v2/partner/manager/head/archives/inviter/list", // 转介绍人
  partnerArchivesAccDetail:
    "/api/v2/partner/manager/head/archives/account/detail/list", // 合伙人钱包账户明细
  partnerArchivesDraft:
    "/api/v2/partner/manager/head/archives/draft/income/list", // 待结算列表
  partnerArchivesSettled:
    "/api/v2/partner/manager/head/archives/settled/income/list", // 已结算列表
  partnerArchivesBenefit:
    "/api/v2/partner/manager/head/archives/history/benefit/list", // 归档-合伙人权益
  partnerArchivesPlan:
    "/api/v2/partner/manager/head/archives/history/plan/list", // 归档-历史合伙人方案
  partnerArchivesInviterHead: "/api/v2/partner/manager/head/archives/inviter/user/head", // 转介绍人详情佣金列表-头部信息
  partnerArchivesInviterList: "/api/v2/partner/manager/head/archives/inviter/commission/order/list", // 转介绍人佣金订单列表
  //集团 合伙人账户
  partnerUserShopList: "/api/v2/partner/manager/head/account/list", //账号门店列表
  partnerUserStatistics: "/api/v2/partner/manager/head/account/statistics", //合伙人账户统计
  partnerAmountList: "/api/v2/partner/manager/head/account/amount/list", //单门店列表


  //连锁 合伙人账户
  partnerUserStatisticsLs: "/api/v2/partner/manager/chain/account/statistics", //合伙人账户统计
  partnerAmountListLs: "/api/v2/partner/manager/chain/account/amount/list", //门店列表（认购金/实际消费/已结算佣金/预存分红/已结算分红）


  //  收款方式
  partnerPayList: "/api/v2/beauty/manager/payorder/pay-type-list", //列表
  partnerPayTypeList: "/api/v2/partner/manager/head/plan/paytype", //列表
  

  //连锁佣金
  partnerChainProfitList: "/api/v2/partner/manager/chain/partnerprofit/list", //列表
  partnerChainProfitDetail:
    "/api/v2/partner/manager/chain/partnerprofit/detail", //列表
  // 修改佣金类型调用(首购/复购)
  partnerUpdatetype: "/api/v2/partner/manager/head/partnerprofit/updatetype",
  partnerGetbankcard: "/api/v2/partner/manager/head/partnerprofit/getbankcard",//批量结算银行卡列表
};

/**
 * @param parameter
 * @returns {*}
 */

export function getPlanSearchAll(parameter) {
  return requesthhr({
    url: userApi.getPlanSearchAll,
    method: "get",
    params: parameter,
  });
}
export function partnerGetbankcard(parameter) {
  return requesthhr({
    url: userApi.partnerGetbankcard,
    method: "get",
    params: parameter,
  });
}
export function getInstitutionList(parameter) {
  return requesthhr({
    url: userApi.getInstitutionList,
    method: "get",
    params: parameter,
  });
}
export function partnerPayTypeList(parameter) {
  return requesthhr({
    url: userApi.partnerPayTypeList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviterHead(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviterHead,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviterList(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviterList,
    method: "get",
    params: parameter,
  });
}
export function partnerUpdatetype(parameter) {
  return requesthhr({
    url: userApi.partnerUpdatetype,
    method: "get",
    params: parameter,
  });
}
export function partnerPayList(parameter) {
  return requesthhr({
    url: userApi.partnerPayList,
    method: "get",
    params: parameter,
  });
}
export function partnerChainProfitList(parameter) {
  return requesthhr({
    url: userApi.partnerChainProfitList,
    method: "get",
    params: parameter,
  });
}
export function partnerChainProfitDetail(parameter) {
  return requesthhr({
    url: userApi.partnerChainProfitDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerUserShopList(parameter) {
  return requesthhr({
    url: userApi.partnerUserShopList,
    method: "get",
    params: parameter,
  });
}
export function partnerUserStatistics(parameter) {
  return requesthhr({
    url: userApi.partnerUserStatistics,
    method: "get",
    params: parameter,
  });
}
export function partnerAmountList(parameter) {
  return requesthhr({
    url: userApi.partnerAmountList,
    method: "get",
    params: parameter,
  });
}
export function partnerUserStatisticsLs(parameter) {
  return requesthhr({
    url: userApi.partnerUserStatisticsLs,
    method: "get",
    params: parameter,
  });
}
export function partnerAmountListLs(parameter) {
  return requesthhr({
    url: userApi.partnerAmountListLs,
    method: "get",
    params: parameter,
  });
}
export function getPlanAll(parameter) {
  return requesthhr({
    url: userApi.getPlanAll,
    method: "get",
    params: parameter,
  });
}
export function getCurrentList(parameter) {
  return requesthhr({
    url: userApi.getCurrentList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesList(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesList,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesDetail(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesInviter(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesInviter,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesAccDetail(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesAccDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesDraft(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesDraft,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesSettled(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesSettled,
    method: "get",
    params: parameter,
  });
}
export function partnerArchivesBenefit(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesBenefit,
    method: "get",
    params: parameter,
  });
}

export function partnerArchivesPlan(parameter) {
  return requesthhr({
    url: userApi.partnerArchivesPlan,
    method: "get",
    params: parameter,
  });
}
export function partnerFhOrder(parameter) {
  return requesthhr({
    url: userApi.partnerFhOrder,
    method: "get",
    params: parameter,
  });
}
export function partnerFhPartnerList(parameter) {
  return requesthhr({
    url: userApi.partnerFhPartnerList,
    method: "get",
    params: parameter,
  });
}
export function partnerFhChainDetailList(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainDetailList,
    method: "get",
    params: parameter,
  });
}
export function partnerFhChainDetail(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerFhChainSave(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainSave,
    method: "post",
    data: parameter,
  });
}

export function partnerProfitDetailupdate(parameter) {
  return requesthhr({
    url: userApi.partnerProfitDetailupdate,
    method: "post",
    data: parameter,
  });
}
export function partnerFhChainSettleDividend(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainSettleDividend,
    method: "post",
    data: parameter,
  });
}
export function partnerFhChainCreateDetail(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainCreateDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerFhChainMonthDetail(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainMonthDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerFhChainCreate(parameter) {
  return requesthhr({
    url: userApi.partnerFhChainCreate,
    method: "post",
    data: parameter,
  });
}
export function partnerFhDetailList(parameter) {
  return requesthhr({
    url: userApi.partnerFhDetailList,
    method: "get",
    params: parameter,
  });
}
export function partnerProfitDetail(parameter) {
  return requesthhr({
    url: userApi.partnerProfitDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerProfitDiscard(parameter) {
  return requesthhr({
    url: userApi.partnerProfitDiscard,
    method: "post",
    data: parameter,
  });
}
export function partnerProfitSettle(parameter) {
  return requesthhr({
    url: userApi.partnerProfitSettle,
    method: "post",
    data: parameter,
  });
}
export function partnerProfitList(parameter) {
  return requesthhr({
    url: userApi.partnerProfitList,
    method: "get",
    params: parameter,
  });
}
export function partnerFhSave(parameter) {
  return requesthhr({
    url: userApi.partnerFhSave,
    method: "post",
    data: parameter,
  });
}
export function partnerFhStatistics(parameter) {
  return requesthhr({
    url: userApi.partnerFhStatistics,
    method: "get",
    params: parameter,
  });
}
export function partnerFhIndex(parameter) {
  return requesthhr({
    url: userApi.partnerFhIndex,
    method: "get",
    params: parameter,
  });
}
export function partnerFhDetail(parameter) {
  return requesthhr({
    url: userApi.partnerFhDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerFhExport(parameter) {
  return requesthhr({
    url: userApi.partnerFhExport,
    responseType: "blob",
    method: "get",
    params: parameter,
  });
}
export function partnerFhExamine(parameter) {
  return requesthhr({
    url: userApi.partnerFhExamine,
    method: "post",
    data: parameter,
  });
}
export function partnerFhAbouchement(parameter) {
  return requesthhr({
    url: userApi.partnerFhAbouchement,
    method: "post",
    data: parameter,
  });
}

export function partnerFhNotify(parameter) {
  return requesthhr({
    url: userApi.partnerFhNotify,
    method: "post",
    data: parameter,
  });
}
export function partnerFhCreate(parameter) {
  return requesthhr({
    url: userApi.partnerFhCreate,
    method: "post",
    data: parameter,
  });
}
export function partnerAdd(parameter) {
  return requesthhr({
    url: userApi.partnerAdd,
    method: "post",
    data: parameter,
  });
}
export function partnerSave(parameter) {
  return requesthhr({
    url: userApi.partnerSave,
    method: "post",
    data: parameter,
  });
}
export function partnerGenerated(parameter) {
  return requesthhr({
    url: userApi.partnerGenerated,
    method: "get",
    params: parameter,
  });
}
export function partnerIndexAll(parameter) {
  return requesthhr({
    url: userApi.partnerIndexAll,
    method: "get",
    params: parameter,
  });
}
export function partnerIndex(parameter) {
  return requesthhr({
    url: userApi.partnerIndex,
    method: "get",
    params: parameter,
  });
}
export function partnerDetail(parameter) {
  return requesthhr({
    url: userApi.partnerDetail,
    method: "get",
    params: parameter,
  });
}
export function partnerCopy(parameter) {
  return requesthhr({
    url: userApi.partnerCopy,
    method: "post",
    data: parameter,
  });
}
export function InstitutionProvinceShop(parameter) {
  return requesthhr({
    url: userApi.InstitutionProvinceShop,
    method: "get",
    params: parameter,
  });
}
export function InstitutionProvince(parameter) {
  return requesthhr({
    url: userApi.InstitutionProvince,
    method: "get",
    params: parameter,
  });
}
export function partnerInstitutionList(parameter) {
  return requesthhr({
    url: userApi.partnerInstitutionList,
    method: "get",
    params: parameter,
  });
}
