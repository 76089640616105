<template>
  <div class="filter">
    <a-row
      class="filter-wrap2 flex"
      v-if="isAllLengths"
    >
      <!-- -->
      <span class="filter_sx">筛选 {{ isAllLength}}</span>
      <span
        v-for="(it,idx) in flitList"
        :key="idx"
      >
        <span
          class="filter_type"
          v-if="it.value&&it.value.length&&it.value[0]"
        >{{it.key}}： <span class="typeitems">
            <span class="overflowElip">{{it.value.join('、')}}</span>
            <i
              class="meiye-icon meiye-close-bold pointer"
              @click="clearsingle(it,'single')"
            >
            </i>
          </span>
        </span>
      </span>
      <!-- <span
        class="filter_type"
        v-if="activeStatus_catagory&&activeStatus_catagory.length&&activeStatus_catagory[0]"
      >类别： <span class="typeitems"> <span class="overflowElip">{{activeStatus_catagory.join('、')}}</span> <i
            class="meiye-icon meiye-close-bold"
            @click="clearflitersingle(e)"
          > 
          </i>
        </span>
      </span>
      <span
        class="filter_type"
        v-if="titleList.length"
      >分类： <span class="typeitems"> <span class="overflowElip">{{titleList.join('、')}}</span> <i
            class="meiye-icon meiye-close-bold"
            @click=";titleList = [];expandedKeys = [];autoExpandParent = false;backupsExpandedKeys = [];selectedKeys=[];permissionInfo.info.permission_id_list=[]"
          ></i></span> </span>
      <span
        class="filter_type"
        v-if="activeStatus_status&&activeStatus_status.length&&activeStatus_status[0]"
      >状态： <span class="typeitems"><span class="overflowElip">{{activeStatus_status.join('、')}}</span> <i
            class="meiye-icon meiye-close-bold"
            @click="activeStatus_status=''"
          ></i></span> </span>
      <span
        class="filter_type"
        v-if="activeStatus_brand&&activeStatus_brand.length&&activeStatus_brand[0]"
      >品牌： <span class="typeitems">{{ activeStatus_brand.join('、') }} <i
            class="meiye-icon meiye-close-bold"
            @click=";activeStatus_brand = ''"
          ></i></span> </span> -->
      <span
        class="filter_clear"
        @click="clearsingle('')"
      >
        <i class="  meiye-icon meiye-shanchu">
        </i>
        清空
      </span>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'TableFilter',
  data() {
    return {
      isAllLengths: "",
    }
  },
  props: {
    flitList: {
      type: Array,
      default: () => [
        // { key: '类别', value: ['客装', '院装'], paramas: 'goods_type' },
        // { key: '分类', value: ['正常', '下架'], paramas: 'goods_category' },
        // { key: '状态', value: ['正常', '下架'], paramas: 'is_show' },
        // { key: '品牌', value: ['蒂芙尼', '正大'], paramas: 'goods_brand' }
      ]
    },
    isShowAll: {
      type: Boolean,
      default: false,
    },
    isAllLength: {
      type: Number,
      default: 0,
    },


  },
  created() {
  },
  mounted() {
  },
  methods: {
    clearsingle(e) {
      this.$emit('transferson', e)
    },


  },
  watch: {
    flitList: {
      handler: function (val) {
        console.log("监听3")
        console.log(val)
        let arr = []
        val.forEach(el => {
          arr = arr.concat(el.value)
        });
        console.log(arr); 
        this.isAllLengths = arr.length
      },
      immediate: true,  // --首次加载时执行监听
      deep: true		  // --值改变时执行监听
    }
  },


}
</script>

<style lang="less" scoped>
.filter-wrap2 {
  background: #fff;
  height: 55px;
  padding: 18px 44px 0 22px;
  align-items: center;
  .filter_sx {
    font-size: 14px;
    font-weight: 400;
    color: @fontColor3;
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #dadada;
    padding-right: 12px;
    margin-right: 18px;
  }
  .filter_type {
    height: 24px;
    background: #f3fafa;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 14px;
    font-weight: 400;
    color: #9ea6a4;
    line-height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    .typeitems {
      font-size: 14px;
      font-weight: 400;
      color: @baseColor11;
      display: flex;
      align-items: center;
      .overflowElip {
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .meiye-close-bold {
        font-size: 9px;
        color: #578380;
        margin: 2px 0 0 4px;
      }
      &:hover {
        .meiye-close-bold {
          color: #436c69;
        }
      }
    }
  }
  .filter_clear {
    cursor: pointer;
    .meiye-routeicon-ashbin {
      color: @fontColor5;
      font-size: 10px;
    }
    &:hover {
      color: @baseColor11;
      .meiye-routeicon-ashbin {
        color: @baseColor11;
      }
    }
  }
}
</style>