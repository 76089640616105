/*
 * @Version: 2.0
 * @Autor: YangKX
 * @Date: 2023-04-06 11:15:40
 * @LastEditors: YangKX
 * @LastEditTime: 2023-04-11 15:22:17
 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import moment from 'moment'
import * as utils from '@/utils/utils'
import * as constant from '@/utils/const'
import api from '@/api'
import request from '@/utils/request'
import requesthhr from '@/utils/requesthhr'
import DetailTitle from "@/components/DetailTitle";

import 'moment/locale/zh-cn'

import './extends/use'
import './extends/directive'
import './extends/prototype'

import store from './store'
import router from './router'
import App from './app' 
import '@/assets/style/nprogress.less'
import '@wytxer/style-utils/lib/common.less' 
import '@/assets/style/global.less'
import './assets/iconfont/middle/iconfont.css' 
import 'huanlong-components/lib/huanlong-components.css' //自定义包样式单独引入
moment.locale('zh-cn')

Vue.prototype.$moment = moment
Vue.prototype.$utils = utils
Vue.prototype.$const = constant
Vue.prototype.$api = api
Vue.prototype.$request = request
Vue.prototype.$requesthhr = requesthhr
Vue.component('DetailTitle', DetailTitle) 

import 'video.js/dist/video-js.css'  //videojs样式 
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper) 
if (process.env.VUE_APP_USE_MOCKER === 'false') {
  require('../mock/index')
} 
import * as echarts from 'echarts'; 
Vue.prototype.$echarts = echarts 
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

