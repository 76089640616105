<template>
  <a-config-provider :locale="locale">
    <template #renderEmpty>
      <!-- <a-empty :image="require('@/assets/images/global-empty.png')"> -->
      <span
        class="mrj-empty-class"
        slot="description"
      > 暂无数据
      </span>
      <!-- </a-empty> -->
    </template>
    <router-view v-if='showBreadcrumb ' />
    <template v-else>
      <layout-base v-if="$route.meta.layout === 'layout-base'  " />
      <layout-user v-if="$route.meta.layout === 'layout-user' " />
      <router-view v-else />
    </template>
  </a-config-provider>
  <!-- <div>
    <router-view v-if='showBreadcrumb' />
    <template v-else>
      <layout-base v-if="$route.meta.layout === 'layout-base'" />
      <layout-user v-if="$route.meta.layout === 'layout-user'" />
      <router-view v-else />
    </template>
  </div> -->

</template>
<script>
import { mapState } from 'vuex'
import { LayoutBase, LayoutUser } from '@/layouts'
import themeColor from '@/components/dev-drawer/theme-color'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  components: {
    LayoutBase,
    LayoutUser
  },
  data() {
    return {
      locale: zhCN,

    }
  },
  created() {
    document.title = '内容管理系统'
  },
  computed: {
    ...mapState({
      app: state => state.app,
      showBreadcrumb: state => state.app.navMode === 'breadcrumb'
    })
  },
  mounted() {
    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', this.onPopState);
    // 如果是开发环境或者是预览环境的话支持自定义切换主题色
    if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_BUILD_ENV === 'preview') {
      themeColor.changeColor(this.app.themeColor)
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    onPopState(e) {
      console.log(this.$route);
      console.log(window.history);
      console.log(this.showBreadcrumb, '/showBreadcrumb');
      location.reload();
      // window.history.go(-1)

      // this.isRouterAlive = false
      // 路由虽然改变 但是没有回显业务的 数据
      // global_menu_obj二级tabs   seconderyMenuObj三级页签tabs  activeTabsKey 上面显示button
      // this.isRouterAlive = true


      // 监听到浏览器回退事件（这里提示用的confirm，是可以自定义的）
      // if (confirm('离开当前页面数据可能会丢失，您确定离开当前页面吗？')) {
      //   // 点击确定回退
      //   window.removeEventListener('popstate', this.onPopState)
      //   window.history.go(-1)

    },
  },
  provide() {
    return {
      reload: this.reload
    }
  },
}
</script>

<style lang="less">
// @import "./assets/style/app.less";
</style>
