const mock = require('mockjs2')
const { builder } = require('../utils')

const user = {
  ok: builder(),
  error: builder(null, 500),
  info: builder(mock.mock({
    id: 1000,
    nickname: '@cname',
    permissions: ['add', 'edit']
  })),
  menus: builder([{
    path: '/',
    component: 'LayoutBase',
    redirect: '/setting/permission',
    children: [
      {
        path: 'users',
        component: 'LayoutBlock',
        title: '用户',
        icon: 'book',
        children: [
          {
            path: 'index',
            component: 'userslist',
            title: '用户管理',
          },
        ]
      },
      {
        path: 'setting',
        component: 'LayoutBlock',
        title: '设置',
        icon: 'database',
        children: [
          {
            path: 'permission',
            component: 'permission',
            icon: '',
            title: '权限管理',
          },
          {
            path: 'rules',
            component: 'rulenamage',
            title: '角色管理',
          },
          {
            path: 'list',
            component: 'companylist',
            title: '公司管理'
          },
          {
            path: 'staff',
            component: 'stafflist',
            title: '员工管理'
          },
          {
            path: 'message',
            component: 'message',
            title: '短信模板'
          },
        ]
      },
    ]
  }])
}

module.exports = {
  'POST /user/login': user.info,
  'POST /user/logout': user.ok,
  'POST /user/info': user.info,
  'POST /menus': user.menus,
  'POST /user/sms/send': user.ok,
  'POST /user/reset/password': user.ok,
  'POST /user/register': user.ok
}
