<template>
  <div class="flex-1 mgt-20">
    <div class="mgb-20 mgl-20">
      <a-radio-group v-model="query.status" @change="getList" class="lable_radiogroup">
        <a-radio-button :value="0">
          待结算
        </a-radio-button>
        <a-radio-button :value="1">
          已结算
        </a-radio-button>
        <a-radio-button :value="2">
          已作废
        </a-radio-button>
      </a-radio-group>
    </div>
    <a-row class="mgl-20">
      <a-col :span="24">
        <span class="ant-input_2">
          <a-input v-model="query.keyword" style="width: 300px;" placeholder="请输入合伙人姓名/电话/佣金订单编号" allowClear class="mgr-12">
            <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
          </a-input>
        </span>
        <a-range-picker class="data_picker" style="width:280px" v-model="dateRange" @change="onChangeTime">
          <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
        </a-range-picker>
        <a-button type="primary" class="table-btn-clk mgl-12" style="margin-left:12px" @click="query.requestPage = 1,getList()">查询</a-button>
        <a-button class="mgl-12 normalbtn ant-btn-link" type="link" @click="onReset">重置</a-button>
        <a-button class="mgl-12 normalbtn ant-btn-grounding" @click="onOpen(1)">批量结算</a-button>
      </a-col>
    </a-row>
    <div class="mgb-12 mgt-12">
      <table-filter :flitList="flitList" :isAllLength="false" :isShowAll="true" @transferson="clearfliterSingle"></table-filter>
    </div>
    <!-- :customRow="rowClick" -->
    <a-table class="table-manage" :scroll="{ x: '100%',y:$utils.getfullHeight(433)}" :row-selection=" { selectedRowKeys: table.selectedRowKeys, onChange: onSelectChange }" @change="tableChanged" :pagination="table.pagination" row-key="id" :loading="table.loading" :columns="query.status == 0?config.bonus.columns_a:config.bonus.columns_b" :data-source="table.tableData">
      <template slot="_time">
        收款时间
        <!-- <div class="cursor-pointer">
          <a-popover :title="null" v-model="visibleTime" trigger="click" placement="bottomLeft">
            <template slot="content">
              <a-range-picker style="width:280px" v-model="dateRange" @change="onChangeTime" />
            </template>
            <span>
              <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-riqi"></i>
              收款时间
              <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
            </span>
          </a-popover>
        </div> -->
      </template>
      <template slot="_shop">
        <div class="cursor-pointer">
          <a-popover :title="null" v-model="visibleInstitution" trigger="click" placement="bottomLeft">
            <template slot="content">
              <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query.institution_id" @change="visibleInstitution=false,query.requestPage = 1,getList()" placeholder="全部门店">
                <a-select-option v-for="it in shopList" :key="it.id" :value="it.id" :label="it.name">
                  {{ it.name }}
                </a-select-option>
              </a-select>
            </template>
            <span>
              <i style="font-size:15px;color:#9EA6A4" class="meiye-icon meiye-mendian"></i>
              门店
              <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_plan">
        <div class="cursor-pointer">
          <a-popover :title="null" v-model="visiblePlan" trigger="click" placement="bottomLeft">
            <template slot="content">
              <a-select class="width-min-200" optionFilterProp="label" showSearch allowClear v-model="query.partner_plan_id" @change="visiblePlan=false,query.requestPage = 1,getList()" placeholder="全部方案">
                <a-select-option v-for="it in schemeList" :key="it.id" :value="it.id" :label="it.scheme_name">
                  {{ it.scheme_name }}
                </a-select-option>
              </a-select>
            </template>
            <span>
              <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-fangan"></i>
              合伙人方案
              <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_status">
        <div class="cursor-pointer">
          <a-popover :title="null" v-model="visibleStatus" trigger="click" placement="bottomLeft">
            <template slot="content">
              <a-select class="width-min-164" allowClear v-model="query.status" @change="visibleStatus=false,query.requestPage = 1,getList()" placeholder="全部状态">
                <a-select-option :value="undefined">全部状态</a-select-option>
                <a-select-option :value="0">待结算</a-select-option>
                <a-select-option :value="1">已结算</a-select-option>
                <a-select-option :value="2">已作废</a-select-option>
              </a-select>
            </template>
            <span>
              <i style="font-size:15px;color:#9EA6A4" class="meiye-icon  meiye-zhuangtai"></i>
              状态
              <i style="font-size:9px" class="meiye-icon meiye-xiala"></i>
            </span>
          </a-popover>
        </div>
      </template>
      <template slot="_1" slot-scope="action, record">
        {{record.shop.shop_abbreviation || '--'}}
      </template>

      <template slot="_10" slot-scope="action, record">
        <a-tooltip placement="topLeft" v-if="record.partnerPlan.scheme_name">
          <template slot="title">
            {{record.partnerPlan.scheme_name}}
          </template>
          {{record.partnerPlan.scheme_name}}
        </a-tooltip>
        <span v-else>--</span>
      </template>
      <template slot="_4" slot-scope="action, record">
        {{record.partner.name || '--'}} {{record.partner.phone || '--'}}
      </template>
      <template slot="_5" slot-scope="action, record">
        {{record.payUser.realname || '--'}} {{record.payUser.mobile || '--'}}
      </template>
      <template slot="_8" slot-scope="action, record">
        <a-button type="primary" v-if="query.status == 0" size="small" class="small_primary" @click.stop="onOpen(2,record)">结算</a-button>
        <a-button size="small" class="mgr-12 smallbtn" @click.stop="toDetail(record)">查看</a-button>
        <a-button size="small" v-if="query.status == 0" style="margin-right:0" class="smallbtn" @click.stop="editUser(record)">编辑</a-button>
      </template>
    </a-table>
    <a-modal class="ak-modal-table-tb" :title="modal.title" :visible="modal.visible" @ok="handleOk" @cancel="handleCancel" :width="modal.title=='结算'?600:1000">
      <div class="mgt-10">
        <template v-if="modal.title=='结算'">
          <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" labelAlign="left">
            <a-form-model-item label="合伙人">{{modal.tableList?.[0]?.partner || '--' }}</a-form-model-item>
            <a-form-model-item label="银行卡号">{{modal.tableList?.[0]?.bank_card_no || '--' }}</a-form-model-item>
            <a-form-model-item label="开户行">{{modal.tableList?.[0]?.bank_name || '--' }}</a-form-model-item>
            <a-form-model-item label="持卡人">{{modal.tableList?.[0]?.name || '--' }}</a-form-model-item>
            <a-form-model-item label="结算佣金金额">
              <span class="theme-color" style="font-size:18px;font-weight:600"> ¥{{modal.data.commission_amount}}</span>
            </a-form-model-item>
            <a-form-model-item label="订单数量">
              {{modal.num}}笔
            </a-form-model-item>
            <div class="ant-row ant-form-item">
              <div class="ant-col ant-col-4 ant-form-item-label ant-form-item-label-left">
                结算方式<a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
                  <template slot="title">
                    线上:通过银行转账方式结算合伙人佣金 <br>
                    线下:通过其他方式结算合伙人佣金
                  </template>
                  <a-icon :style="{ color: '#819190',marginLeft:'4px' }" type="info-circle" />
                </a-tooltip>
              </div>
              <div class="ant-col ant-col-16 ant-form-item-control-wrapper">
                <div class="ant-form-item-control">
                  <a-radio-group v-model="modal.data.settlement_type">
                    <a-radio :value="1">
                      线上
                    </a-radio>
                    <a-radio :value="2">
                      线下
                    </a-radio>
                  </a-radio-group>
                </div>
              </div>
            </div>
            <a-form-model-item label="通知方式">
              系统通知
            </a-form-model-item>
          </a-form-model>
        </template>
        <template v-else>
          <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" labelAlign="left">
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="累计结算佣金总额">
                  <span class="theme-color" style="font-size:18px;font-weight:600"> ¥{{modal.data.commission_amount}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="订单数量">
                  {{modal.num}}笔
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <div>
                  <div class="ant-col ant-col-8 ant-form-item-label ant-form-item-label-left">
                    结算方式<a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
                      <template slot="title">
                        线上:通过银行转账方式结算合伙人佣金 <br>
                        线下:通过其他方式结算合伙人佣金
                      </template>
                      <a-icon :style="{ color: '#819190',marginLeft:'4px' }" type="info-circle" />
                    </a-tooltip>
                  </div>
                  <div class="ant-col ant-col-14 ant-form-item-control-wrapper">
                    <div class="ant-form-item-control">
                      <a-radio-group v-model="modal.data.settlement_type">
                        <a-radio :value="1">
                          线上
                        </a-radio>
                        <a-radio :value="2">
                          线下
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="通知方式">
                  系统通知
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </template>
        <template v-if="modal.title=='批量结算'">
          <a-table class="detailsTable" :scroll="{ x: '100%',y:300}" :pagination="false" :columns="columnsDetail" :data-source="modal.tableList">
            <template slot="_0" slot-scope="action, record">
              <a-tooltip>
                <template slot="title">
                  {{record.partner || '--'}}
                </template>
                {{record.partner || '--'}}
              </a-tooltip>
            </template>
            <template slot="_1" slot-scope="action, record">
              <a-tooltip>
                <template slot="title">
                  {{record.bank_card_no || '--'}}
                </template>
                {{record.bank_card_no || '--'}}
              </a-tooltip>
            </template>
            <template slot="_4" slot-scope="action, record">
              <a-tooltip>
                <template slot="title">
                  {{record.shop_name || '--'}}
                </template>
                {{record.shop_name || '--'}}
              </a-tooltip>
            </template>
            <template slot="_2" slot-scope="action, record">
              <a-tooltip>
                <template slot="title">
                  {{record.bank_name || '--'}}
                </template>
                {{record.bank_name || '--'}}
              </a-tooltip>
            </template>
          </a-table>
        </template>
      </div>
    </a-modal>
  </div>
</template>
<script> 
import config from '../config'
import TableFilter from '@/components/intimate/tableFilter/index'
import Msg from '@/components/mrj-menu/msg'
import { partnerProfitList, partnerIndexAll, InstitutionProvince, partnerProfitSettle, partnerGetbankcard } from '@/api/partner'
export default {
  components: { TableFilter },
  data() {
    return {
      config,
      shopList: [],
      flitList: [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '合伙人方案', value: [], paramas: 'partner_plan_id' },
      ],
      schemeList: [],
      visibleInstitution: false,
      visibleTime: false,
      visibleStatus: false,
      visiblePlan: false,
      columnsDetail: [
        {
          title: "合伙人",
          dataIndex: "partner",
          key: "_0",
          width: 160,
          ellipsis: true,
          scopedSlots: { customRender: "_0" },
        },
        {
          title: "门店",
          dataIndex: "shop_name",
          key: "_4",
          ellipsis: true,
          width: 120,
          scopedSlots: { customRender: "_4" },
        },
        {
          width: 140,
          title: "银行卡号",
          dataIndex: "bank_card_no",
          ellipsis: true,
          key: "_1",
          scopedSlots: { customRender: "_1" },
        },
        {
          title: "开户行",
          dataIndex: "bank_name",
          ellipsis: true,
          width: 140,
          key: "_2",
          scopedSlots: { customRender: "_2" },
        },
        {
          title: "持卡人",
          dataIndex: "name",
          customRender: (str) => str || "--",
          width: 90,
          ellipsis: true,
          key: "_3",
          scopedSlots: { customRender: "_3" },
        }, {
          title: "订单数量",
          dataIndex: "order_number",
          width: 90,
          key: "_5",
          scopedSlots: { customRender: "_5" },
        }, {
          title: "结算金额",
          dataIndex: "commission_amount",
          width: 100,
          key: "_6",
          customRender: (str) => "¥" + str || "¥ 0.00",
          scopedSlots: { customRender: "_6" },
        },
      ],
      modal: {
        title: '结算',
        visible: false,
        num: 1,
        form: '',
        tableList: [],
        data: {
          id: '',
          settlement_type: 1,
          commission_amount: '',
        }
      },
      queryStatusList: [
        {
          status: 0,
          label: '待结算'
        },
        {
          status: 1,
          label: '已结算',
        },
        {
          status: 2,
          label: '已作废',
        }
      ],
      dateRange: [],
      query: {
        perPage: 10,
        requestPage: 1,
        institution_id: undefined,
        keyword: undefined,
        startTime: undefined,
        endTime: undefined,
        partner_plan_id: undefined,
        status: 0,
      },
      table: {
        tableData: [],
        loading: false,
        selectedRowKeys: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 10, //每页中显示10条数据
          showSizeChanger: true,
          showQuickJumper: true,
        },
      }
    }
  },
  created() {
    this.getList();
    // 获取门店
    InstitutionProvince({ isFilterate: 0, brand_id: localStorage.getItem('global_brand_id') }).then(res => {
      let list = []
      res.data.forEach((v) => {
        list = list.concat(v.institution)
      })
      if (list.length) {
        this.shopList = list;
      }
      console.log('this.shopList :>> ', this.shopList);
    })
    partnerIndexAll().then(res => {
      console.log(res.data, "data");
      this.schemeList = res.data
    })
  },
  watch: {
    'query.institution_id': {
      handler(val) {
        console.log('val :>> ', val);
        if(!val){
          this.flitList[0].value = []
        }
        let str = this.shopList.find(v => v.id == val);
        if (str) {
          this.flitList[0].value = [str.name]
        }
      }
    },
    'query.partner_plan_id': {
      handler(val) {
        console.log('val :>> ', val);
        if(!val){
          this.flitList[1].value = []
        }
        let str = this.schemeList.find(v => v.id == val);
        if (str) {
          this.flitList[1].value = [str.scheme_name]
        }
      }
    }
  },
  methods: {
    clearfliterSingle(e) {
      console.log(e);
      if (e) {
        // 单个清除 根据业务 去除 
        this.query[e.paramas] = undefined
        this.flitList.forEach(el => {
          if (el.paramas == e.paramas) {
            el.value = []
          }
        })
        this.getList()
      } else {
        // 全部清除
        this.clearfliterAll()
      }
    },
    // 清除 类别  分类 品牌  状态 恢复 初始值
    clearfliterAll() {
      // 清除分类
      this.flitList = [
        { key: '门店', value: [], paramas: 'institution_id' },
        { key: '合伙人方案', value: [], paramas: 'partner_plan_id' },
      ]
      this.query = {
        perPage: 10,
        requestPage: 1,
        institution_id: undefined,
        keyword: undefined,
        startTime: undefined,
        endTime: undefined,
        partner_plan_id: undefined,
        status: 0,
      }
      this.getList()
    },
    rowClick(record, index) {
      return {
        on: {
          click: () => {
            this.toDetail(record)
          }
        }
      }
    },
    getfullHeight(val) {
      console.log(document.body.offsetHeight - val);
      return document.body.offsetHeight - val - 159 + 16
    },
    Getbankcard() {
      partnerGetbankcard({ id: this.modal.data.id }).then((res) => {
        this.modal.tableList = res.data.list;
      })
    },
    onOpen(type, item) {
      console.log('item :>> ', item);
      if (type == 2) {
        this.modal.title = '结算';
        this.modal.data.commission_amount = item.commission_amount;
        this.modal.data.id = item.id;
        this.modal.form = item;
        this.modal.num = 1
        this.Getbankcard();
      } else {
        this.modal.title = '批量结算';
        if (this.table.selectedRowKeys.length == 0) return this.$message.warning('请选择批量汇入的订单！')
        var money = 0;
        this.table.selectedRowKeys.forEach(num => {
          let str = this.table.tableData.find(v => v.id == num)
          let price = str.commission_amount
          money = Number(money) + Number(price)
        });
        console.log('money :>> ', money);
        this.modal.data.commission_amount = money.toFixed(2);;
        this.modal.num = this.table.selectedRowKeys.length;
        this.modal.data.id = this.table.selectedRowKeys.toString();
        this.Getbankcard();
      }
      this.modal.visible = true
    },
    onReset() {
      this.query.perPage = 10;
      this.query.requestPage = 1;
      this.query.institution_id = undefined;
      this.query.keyword = undefined;
      this.query.partner_plan_id = undefined;
      this.query.startTime = undefined;
      this.query.endTime = undefined;
      this.dateRange = [];
      this.getList()
    },
    onChangeTime(rec) {
      this.visibleTime = false;
      console.log('rec :>> ', rec);
      this.query.startTime = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.query.endTime = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      this.getList()
    },
    handleOk() {
      const hide = this.$message.loading('正在提交..', 0)
      partnerProfitSettle(this.modal.data).then((res) => {
        this.modal.visible = false;
        this.getList()
      }).finally(r => {
        hide()
      })
    },
    handleCancel() {
      this.modal.visible = false
    },
    getList() {
      let self = this
      self.table.loading = true
      const params = {
        ...self.query
      }
      return partnerProfitList(params).then(res => {
        console.log(res.data, "data");
        const { data = [], total = 0 } = res.data
        self.table.tableData = data
        self.table.selectedRowKeys = []
        self.table.pagination = Object.assign({}, { pageSize: this.query.perPage, current: this.query.requestPage }, {
          total: total
        })
      }).finally(r => {
        self.table.loading = false
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.table.selectedRowKeys = selectedRowKeys
    },
    tableChanged(pagination, filters, sorter, { currentDataSource }) {
      const { current, pageSize } = pagination
      const { field, order } = sorter
      this.query = Object.assign(this.query, { perPage: Number(pageSize), requestPage: Number(current) })
      this.getList()
    },
    //查看详情
    toDetail(e) {
      this.$emit('onType')
      Msg.$emit('changethirdaryShow', false)
      this.$router.push({ path: this.$route.path, query: { type: 1, id: e.id } })
    },
    // 编辑
    editUser(e) {
      this.$emit('onType')
      Msg.$emit('changethirdaryShow', false)
      this.$router.push({ path: this.$route.path, query: { type: 2, id: e.id } })
    },
  },

}
</script>
<style lang="less" scoped>
.personnelfiles {
  background: #fff;
  padding: 20px 12px 0px 20px;
  margin: 0 0 18px 0 !important;
}
/deep/.ant-table-fixed-left,
/deep/.ant-table-fixed-right {
  top: 0;
}
</style>