<template>
  <div style="margin-bottom: 14px;">
    <a-row class="flex">
      <a-col :lg="14" :md="24" :sm="24" :xs="24">
        <a-row class="main-top-info flex align-item-center">
          <a-col :xs="3" style="min-width: 106px;height: 93px" class="height100">
            <a-avatar shape="square" v-if="img" class="detail__comp-img cursor-pointer" style="border-radius: none;" :src="img" />
            <a-avatar shape="square" v-else class="detail__comp-img cursor-pointer" style="font-size: 40px;line-height: 86px;border-radius: none;" icon="file-image" />
          </a-col>
          <a-col :xs="21" :style="{height: img?'80px':'auto'}">
            <div class="main-top-info-wrap flex flex-column justify-between" style="white-space:nowrap">
              <div class="flex align-item-start">
                <!--    justify-between   -->
                <a-tooltip placement="top" :title="title">
                  <div class="main-top-info-wrap-title ant-table-row-cell-ellipsis">{{title || '--'}}</div>
                </a-tooltip>
                <!-- <a-tooltip placement="top" :title="id" v-if="id!==null">
                  <div class="main-top-info-wrap-uid ant-table-row-cell-ellipsis">UID {{id || '--'}}</div>
                </a-tooltip>
                <a-tooltip placement="top" :title="code" v-if="code!==null">
                  <div class="main-top-info-wrap-uid ant-table-row-cell-ellipsis">机构代码 {{code || '--'}}
                    <a-icon v-if="code" type="copy" @click.stop="copyText(code)" :style="{ color: '#3C5FCE' }" />
                  </div>
                </a-tooltip> -->
                <span class="mgl-12" size="small" v-if="statusText!==null" :class="statusClass">{{statusText}}</span>
              </div>
              <template v-if="subTitle">
                <span style="font-size:16px;color: #33323F;">{{subTitle}}</span>
              </template>
              <!-- <template v-if="tags.length">
                <div class="flex flex-column" style="padding-top: 18px;height: 60px;flex-wrap: wrap;align-content: flex-start;">
                  <template v-for="it in tags">
                    <a-badge color="#74ACFF" v-if="it.tag_name" :text="it.tag_name" :key="it.id" class="mgr-12">
                    </a-badge>
                  </template>
                </div>
              </template>
              <template v-else>
                <slot name="tags" />
              </template> -->
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :lg="10" :md="24" :sm="24" :xs="24" class="text-right flex align-item-center justify-end">
        <slot name="global-title-btn" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    subTitle: {
      type: Number | String,
    },
    img: {
      type: String
    },
    title: {
      type: String
    },
    id: {},
    code: {},
    statusClass: {
      type: String,
      default: 'mrj-span-tag-green'
    },
    statusText: {
      type: String,
      default: '未知'
    },
    tags: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    previewAvatar() {
      this.$refs.GlobalPreview.show({ url: this.img })
    },
    copyText(text) {
      var input = document.createElement("input");   // js创建一个input输入框
      input.value = text;  // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      console.log('document.execCommand("Copy") :>> ', document.execCommand("Copy"));
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$notification.open({
        message: '提示',
        description: '已复制机构代码！'
      });
    },
  }

}
</script>

<style lang="less" scoped>
.detail__comp-img {
  border-radius: 2px;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 3px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #d5dde5;
    box-shadow: 0px 2px 4px 0px rgba(204, 209, 228, 0.35);
  }
}
</style>